import {eventBus} from '@/main'
export default {
	methods:{
		validText(text) {
			if (text && text.charAt(0) == ' ') text = text.substring(1)
			if (text && text.charAt(text.length - 1) == ' ') text = text.substring(0, text.length - 1)
			return text
		},
		fieldFocus(field, startPos, endPos) {
			field.setRangeText(this.newElem, startPos, endPos, 'select')
			field.focus();
			field.setSelectionRange(startPos + this.newElem.length, endPos + this.newElem.length)
			field.focus();
			eventBus.$emit('saveEditorChanges', field.value);
		},
		newH1(id) {
			let field = this.$refs.textarea1;
			let startPos = field.selectionStart;

			var startLinePos = 0;
			var currentLine = null;
			
			field.value.split('\n').forEach(line => {
				if(currentLine) return false;

				if(startLinePos <= startPos && startLinePos + (line.length + 1) >= startPos) {
					currentLine = line;
				} else {
					startLinePos += (line.length + 1);
				}
			})

			this.newElem = `\n# ${currentLine}\n`
			
			this.fieldFocus(field, startLinePos, startLinePos + currentLine.length+1)

			this.docLazyUpdate({markdown1: field.value}, id)
		},
		newImg(url, id) {
			let field = this.$refs.textarea1;
			let startPos = field.selectionStart || field.value.length;
			let endPos = field.selectionEnd || field.value.length;
			let text = field.value.substring(startPos, endPos);

			this.validText(text)
				
			this.newElem = `\n ![](${url}) \n`

			this.fieldFocus(field, startPos, endPos)

			this.docLazyUpdate({markdown1: field.value}, id)
		},
		newH2(id) {
			let field = this.$refs.textarea1;
			let startPos = field.selectionStart;

			var startLinePos = 0;
			var currentLine = null;
			
			field.value.split('\n').forEach(line => {
				if(currentLine) return false;

				if(startLinePos <= startPos && startLinePos + (line.length + 1) >= startPos) {
					currentLine = line;
				} else {
					startLinePos += (line.length + 1);
				}
			})

			this.newElem = `\n## ${currentLine}\n`
			
			this.fieldFocus(field, startLinePos, startLinePos + currentLine.length+1)

			this.docLazyUpdate({markdown1: field.value}, id)
		},
		newH3(id) {
			let field = this.$refs.textarea1;
			let startPos = field.selectionStart;

			var startLinePos = 0;
			var currentLine = null;
			
			field.value.split('\n').forEach(line => {
				if(currentLine) return false;

				if(startLinePos <= startPos && startLinePos + (line.length + 1) >= startPos) {
					currentLine = line;
				} else {
					startLinePos += (line.length + 1);
				}
			})

			this.newElem = `\n### ${currentLine}\n`
			
			this.fieldFocus(field, startLinePos, startLinePos + currentLine.length+1)

			this.docLazyUpdate({markdown1: field.value}, id)
		},
		newItalic(id) {
			let field = this.$refs.textarea1;
			let startPos = field.selectionStart;
			let endPos = field.selectionEnd;
			let text = field.value.substring(startPos, endPos);

			this.validText(text)

			if (field.selectionStart == field.selectionEnd) {
				eventBus.$emit('showEditorFlash', 'Please select text to use this feature');
				return false;
			} else {
				this.newElem = ` _${text}_ `
			}

			this.fieldFocus(field, startPos, endPos)

			this.docLazyUpdate({markdown1: field.value}, id)
		},
		newBold(id) {
			let field = this.$refs.textarea1;
			let startPos = field.selectionStart;
			let endPos = field.selectionEnd;
			let text = field.value.substring(startPos, endPos);

			this.validText(text)

			if (field.selectionStart == field.selectionEnd) {
				eventBus.$emit('showEditorFlash', 'Please select text to use this feature');
				return false;
			} else {
				this.newElem = ` **${text}** `
			}

			this.fieldFocus(field, startPos, endPos)

			this.docLazyUpdate({markdown1: field.value}, id)
		},
		newListUl(id) {
			let field = this.$refs.textarea1;
			let startPos = field.selectionStart;

			var startLinePos = 0;
			var currentLine = null;
			
			field.value.split('\n').forEach(line => {
				if(currentLine) return false;

				if(startLinePos <= startPos && startLinePos + (line.length + 1) >= startPos) {
					currentLine = line;
				} else {
					startLinePos += (line.length + 1);
				}
			})

			this.newElem = ` * ${currentLine} `
			
			this.fieldFocus(field, startLinePos, startLinePos + currentLine.length+1)

			this.docLazyUpdate({markdown1: field.value}, id)
		},
		newListOl(id) {
			let field = this.$refs.textarea1;
			let startPos = field.selectionStart;

			var startLinePos = 0;
			var currentLine = null;
			
			field.value.split('\n').forEach(line => {
				if(currentLine) return false;

				if(startLinePos <= startPos && startLinePos + (line.length + 1) >= startPos) {
					currentLine = line;
				} else {
					startLinePos += (line.length + 1);
				}
			})

			this.newElem = `1. ${currentLine} `
			
			this.fieldFocus(field, startLinePos, startLinePos + currentLine.length+1)

			this.docLazyUpdate({markdown1: field.value}, id)
		},
		newLine(id) {
			let field = this.$refs.textarea1;
			let startPos = field.selectionStart;
			let endPos = field.selectionEnd;

			this.newElem = `\n`

			this.fieldFocus(field, startPos, endPos)

			this.docLazyUpdate({markdown1: field.value}, id)
		},
	}
}



