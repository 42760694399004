<template>
    <div>
        <b-modal :id="id" 
                class="modal"
                hide-footer
				hide-header
				size="lg"
				body-class="modal-main">
            <a href="#" class="close-btn" @click.prevent="$bvModal.hide(id)"></a>
            <div v-if="!isMsgSent">
                <h5 >We offer a wide variety of services depending on your needs.</h5>
                <h6>Please fill in the details</h6>
                <form @submit.prevent="corporateFeedback">
                    <div class="form-group mb-3">
                        <label class="form-label">Company Name</label>
                        <input v-model="companyName" class="company-name form-control" type="text" placeholder="e.g. ElegantDoc" required>
                        <span class="focus-border"></span>
                    </div>
                    <div class="form-group mb-3">
                        <label class="form-label">Company Size</label>
                        <select class="form-select" v-model="companySize" required>
                            <option>0 - 5</option>
                            <option>5 - 50</option>
                            <option>Above 50</option>
                        </select>
                    </div>
                    <div class="form-group mb-3">
                        <label class="form-label">What features do you look for the most?</label>
                        <textarea v-model="bodyText" class="company-name form-control" required></textarea>
                        <span class="focus-border"></span>
                    </div>
                    <Button class="form-btn">Send</Button>
                </form>
            </div>
            <div v-if="isMsgSent">
                <h4>Message sent successfully, we'll reach back to you as soon as possible.</h4>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Button from "@/components/Atoms/Button";

export default {
    name: 'CorporatePlan',
    props: {
        id: {
            type: String,
            default: 'modal'
        },
    },
    components: {Button},
    data() {
        return {
            companySize: '0 - 5',
            companyName: '',
            bodyText: '',
            isMsgSent: false
        }
    },
    methods: {
        corporateFeedback() {
            fetch('https://api.duckist.com/baunit/new_feedback', {
                method: 'PUT',
                body: JSON.stringify({
                    first_name: 'ElegantDoc',
                    last_name: `Corporate Plan Purchase`,
                    email: 'elegantdoc@elegantdoc',
                    phone: '+123456789',
                    body: JSON.stringify({
                        companyName: this.companyName,
                        companySize: this.companySize,
                        body: this.bodyText
                    })
                }),
                headers: { "Content-Type": 'application/json' }
            }).then(res => res.json())
                .then(data => data.status == 'ok' ? this.isMsgSent = true : this.isMsgSent = false)
        }
    }
}
</script>

<style scoped lang="scss">
	@import "@/assets/scss/modals/modal-upgrade.scss";
    .form-group {
		position: relative;

        & * {
            text-align: left;
        }

        textarea {
            min-height: 250px;
            resize: none;
        }
	}
    .form-select {
		outline: none;
		box-shadow: unset;
		padding: 12px 16px;
		color: #1E2532;
		height: 54px;
		border-radius: 4px;

		background-color: rgb(239, 242, 249);
		border: 0;

		&:focus {
			outline: none;
			box-shadow: unset;
		}
	}

	.form-label {
		color: rgb(130, 139, 162);
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		margin-bottom: 6px;
		width: 100%;
	}

    .focus-border {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: #2192EF;
        transition: 0.4s;
    }
    .form-control {
        outline: none;
        box-shadow: unset;
        padding: 12px 16px;
        height: 54px;
        border-bottom: 1px solid #ccc;
        border-radius: 4px;
        background-color: #EFF2F9;
        border: none;
        transition: 400ms ease-in-out border-radius;

        &:focus {
            outline: none;
            box-shadow: unset;
            border-radius: 4px 4px 0 0;
            background-color: #EFF2F9;
        }
    }

    .form-control:focus ~ .focus-border {
        width: 100%;
        transition: 0.4s;
    }

    .form-btn {
        width: 100%;
    }
</style>