<template>
	<div class="">
		<InputField :id="id" labelBody="Doc Title" keyToEdit="title" inputType="text" :data="doc.title" />
		<AdvancedOptions @click.prevent.native="settings_collapsed = !settings_collapsed" :settings_collapsed="settings_collapsed" />
		<div :class="!settings_collapsed ? 'collapsed not-collapsed' : 'collapsed'">
			<InputField :id="id" labelBody="Title 1" keyToEdit="txt1" inputType="text" :data="doc.txt1" />
			<InputField :id="id" labelBody="Title 2" keyToEdit="txt2" inputType="text" :data="doc.txt2" />
			<FileUploader :id="id" labelBody="Front Page Image" keyToEdit='file1' inputType="file" inputID="file-input" :isFile="doc.file1" />
			<div class="mb-3" @click="show_designs_modal()">
				<label style="display: block;">
					<div class="form-file">
						<span>Change Frontpage Design</span>
						<Button class="file-upload-button">Choose</Button>
					</div>
				</label>
			</div>
			<FileUploader :id="id" labelBody="Logo" keyToEdit='file2' inputType="file" inputID="logo-file-input" :isFile="doc.file2" />
			<div class="two-fields-one-row">
				<ColorPicker :id="id" name="Primary Text Color" :data="color1" obj_key="color1" />
				<ColorPicker :id="id" name="Primary Background Color" :data="color2" obj_key="color2" />
				<ColorPicker :id="id" name="Secondary Text Color" :data="color3" obj_key="color3" />
				<ColorPicker :id="id" name="Secondary Background Color" :data="color4" obj_key="color4" />
				
				<SelectField :id="id" labelBody="H1 Font Size" keyToEdit="size1" :data="doc.size1" :itemsToLoopThrough="fontSizes" />
				<SelectField :id="id" labelBody="H2 Font Size" keyToEdit="size2" :data="doc.size2" :itemsToLoopThrough="fontSizes" />
				<SelectField :id="id" labelBody="H3 Font Size" keyToEdit="size3" :data="doc.size3" :itemsToLoopThrough="fontSizes" />
				<SelectField :id="id" labelBody="Text Font Size" keyToEdit="size4" :data="doc.size4" :itemsToLoopThrough="fontSizes" />
			</div>
			<div v-if="userInfo[0].plan == 'free' || userInfo[0].plan == 'pro'" class="form-check mb-3" style="cursor: pointer" @click="$bvModal.show('modal-upgrade')">
				<input checked disabled class="form-check-input" type="checkbox" id="elegant_doc_reference">
				<label class="form-check-label" style="cursor: pointer" for="elegant_doc_reference">Show ElegantDoc Reference</label>
			</div>
			<label v-else for="elegant_doc_reference" class="form-check mb-3" style="cursor: pointer">
				<input ref="ed_reference_input" class="form-check-input" type="checkbox" v-model="show_ed_reference" id="elegant_doc_reference" @input="show_ed_reference = !show_ed_reference, docUpdate({show_ed_reference: JSON.stringify(show_ed_reference)}, id)">
				<span class="form-check-label" style="cursor: pointer; user-select: none;" for="elegant_doc_reference">Show ElegantDoc Reference</span>
			</label>
			<SelectField :id="id" labelBody="Page Numbering" keyToEdit="txt3" :data="doc.txt3" :itemsToLoopThrough="page_numbering" />
		</div>
		<Markdown :id="id" markdown_num="markdown1" :data="doc.markdown1" labelBody="Body" />

		<Flash ref="show"/>
		<ModalUpgradePlan :id="'modal-upgrade'"/>
		<DesignsModal :id="'designs-modal'" :template_id="id" :designs_src="report_designs" design_key="txt9" />
	</div>
</template>

<script> 
	import {eventBus} from '@/main'
	// Modals
	import DesignsModal from "@/components/Modals/DesignsModal.vue";
	import ModalUpgradePlan from "@/components/Modals/ModalUpgradePlan";
	// Atoms
	import Flash from "@/components/Atoms/Flash";
	import ColorPicker from "@/components/Atoms/ColorPicker";
	import Button from "@/components/Atoms/Button";
	import InputField from "@/components/Atoms/InputField";
	import SelectField from "@/components/Atoms/SelectField";
	import FileUploader from "@/components/Atoms/FileUploader";
	import AdvancedOptions from "@/components/Atoms/AdvancedOptions";
	// Organisms
	import Markdown from "@/components/Organisms/Markdown";
	// Mixins
	import editor from '@/mixins/editor';
	import apiCalls from '@/mixins/apiCalls';

	export default {
		name: 'Report',
		mixins:[editor, apiCalls],
		props:{
			doc:{ type: Object },
			id:{ type: String },
			userInfo: { type: Array }
		},
		data() {
			return {
				settings_collapsed: true,

				color1: {hex: this.doc.color1 || "#21405b"},
				color2: {hex: this.doc.color2 || "#ffffff"},
				color3: {hex: this.doc.color3 || "#ff7a00"},
				color4: {hex: this.doc.color4 || "#5b3eb7"},

				page_numbering: [
					{id: "0", title: "No Page Numbering", value: "no_page_numbering"},
					{id: "1", title: "Left and Right", value: "left_and_right"},
					{id: "2", title: "Right and Left", value: "right_and_left"},
					{id: "3", title: "Left", value: "left"},
					{id: "4", title: "Right", value: "right"},
				],
				page_numbering_value: this.doc.txt3 || "no_page_numbering",

				report_designs: [
                    {
                        src: require('@/assets/images/report-design-1.png'),
                        design_name: 'template_1'
                    }, {
                        src: require('@/assets/images/report-design-2.png'),
                        design_name: 'template_2'
                    }
                ],

				fontSizes: [
					{ title: '18px', value: 18 },
					{ title: '20px', value: 20 },
					{ title: '24px', value: 24 },
					{ title: '32px', value: 32 }
				],
				show_ed_reference: JSON.parse(this.doc.show_ed_reference)
			}
		},
		components:{
			Flash,
			ColorPicker,
			ModalUpgradePlan,
			Markdown,
			Button,
			DesignsModal,
			InputField,
			SelectField,
			FileUploader,
			AdvancedOptions
		},
		methods: {
			show_designs_modal() {
				this.$bvModal.show('designs-modal')
				setTimeout(() => document.querySelector('.VueCarousel-inner').style.visibility = 'visible', 50)
			}
		},
		mounted() {
			eventBus.$on('saveEditorChanges', (value) => {
				this.doc.body = value;
			});
		}
	}
</script>
<style scoped lang="scss">
	.file-upload-button {
		font-size: 14px !important;
		border-radius: 4px !important;
		padding: .4rem .6rem;
		background-color: #2192EF !important;
		font-weight: 600 !important;
		pointer-events: none;
		margin-left: auto;
	}

	.collapsed {
		padding: 0;
		height: 0;
		overflow-y: hidden;
		outline: none;
		transition: 200ms ease-in-out height,
					200ms ease-in-out padding,
					200ms ease-in-out box-shadow;
	}
	.not-collapsed {
		height: calc(1030px + .75rem);
		overflow: inherit;
	}
	@media (max-width: 1095px) {
		.not-collapsed { height: calc(1045px + .75rem); }	
	}
	@media (max-width: 1019px) {
		.not-collapsed { height: calc(1065px + .75rem); }
	}

	.two-fields-one-row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 2rem;
		margin-top: 6px;
	}
</style>
