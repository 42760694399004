<template>
    <div class="container">
        <div>
            <h1>Page Not Found</h1>
            <h2>The page you're trying to access doesn't exist</h2>
            <router-link to="/docs">
                <Button class="btn" btn_name="Go to Docs >" />
            </router-link>
        </div>
    </div>
</template>

<script>
import Button from '@/components/Atoms/Button.vue';
export default {
    components: {
        Button
    }
}
</script>

<style scoped lang="scss">
.container {
    display: grid;
    place-items: center;
    min-height: 80vh;
    text-align: center;

    h1 {
        font-weight: 600;
    }
    h2 {
        margin: 1.5rem 0;
    }
    .btn {
        font-size: 20px !important;
        padding: 10px 20px;
        border-radius: 10px !important;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: capitalize;
        color: #FFFFFF;
        border-radius: 0;
        background: #273c75;
        border:0;
        outline: none;
        box-shadow: unset;
        &:hover {
            background: #090f22;
        }
    }
}
</style>