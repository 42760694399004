<template>
    <div id="modal-upgrade" class="modal-upgrade-container">
        <h1 class="title header-title text-center">My Billing</h1>
		<p class="header-subtitle text-center">For better features upgrade to a better plan!</p>
        <BillingCards />
        <Flash ref="show" />
    </div>
</template>

<script>
import Button from "@/components/Atoms/Button";
import Flash from "@/components/Atoms/Flash";
import BillingCards from "@/components/Molecules/BillingCards";

export default {
    components: {
        Button,
        Flash,
        BillingCards
    }
}
</script>

<style lang="scss" scoped>
    .modal-upgrade-container {
        padding: 1rem .5rem 2rem;
    }
    .header-title {
        margin-top: 2rem;
        font-size: 38px !important;
        font-weight: 600 !important;
    }
    .header-subtitle {
        margin-bottom: 3rem;
        font-size: 1.1rem;
    }
</style>