<template>
	<div class="">
		<InputField :id="id" labelBody="Doc Title" keyToEdit="title" inputType="text" :data="doc.title" />
		<div class="two-fields-one-row">
			<InputField :id="id" labelBody="Title 1" keyToEdit="txt1" inputType="text" :data="doc.txt1" />
			<InputField :id="id" labelBody="Title 2" keyToEdit="txt2" inputType="text" :data="doc.txt2" />
			<InputField :id="id" labelBody="To Title" keyToEdit="txt3" inputType="text" :data="doc.txt3" />
			<InputField :id="id" labelBody="Recipient" keyToEdit="txt4" inputType="text" :data="doc.txt4" />
		</div>
		<FileUploader :id="id" labelBody="Add Medal Image" keyToEdit='file1' inputType="file" inputID="file-input" :isFile="doc.file1" />
		<div v-if="userInfo[0].plan == 'free' || userInfo[0].plan == 'pro'" class="form-check mb-3" style="cursor: pointer" @click="$bvModal.show('modal-upgrade')">
			<input checked disabled class="form-check-input" type="checkbox" id="elegant_doc_reference">
			<label class="form-check-label" style="cursor: pointer" for="elegant_doc_reference">Show ElegantDoc Reference</label>
		</div>
		<label v-else for="elegant_doc_reference" class="form-check mb-3" style="cursor: pointer">
			<input ref="ed_reference_input" class="form-check-input" type="checkbox" v-model="show_ed_reference" id="elegant_doc_reference" @input="show_ed_reference = !show_ed_reference, docUpdate({show_ed_reference: JSON.stringify(show_ed_reference)}, id)">
			<span class="form-check-label" style="cursor: pointer; user-select: none;" for="elegant_doc_reference">Show ElegantDoc Reference</span>
		</label>
		<div class="two-fields-one-row">
			<SelectField :id="id" labelBody="Title Font Size" keyToEdit="size1" :data="doc.size1" :itemsToLoopThrough="fontSizes" />
			<SelectField :id="id" labelBody="Subtitle Font Size" keyToEdit="size2" :data="doc.size2" :itemsToLoopThrough="fontSizes" />
			<SelectField :id="id" labelBody="H3 Font Size" keyToEdit="size3" :data="doc.size3" :itemsToLoopThrough="fontSizes" />
			<SelectField :id="id" labelBody="Text Font Size" keyToEdit="size4" :data="doc.size4" :itemsToLoopThrough="fontSizes" />
			<ColorPicker :id="id" name="Primary Text Color" :data="color1" obj_key="color1" />
			<ColorPicker :id="id" name="Primary Background Color" :data="color2" obj_key="color2" />
			<ColorPicker :id="id" name="Secondary Background Color" :data="color3" obj_key="color3" />
		</div>
		<Markdown :id="id" markdown_num="markdown1" :data="doc.markdown1" labelBody="Body" />
		<InputField :id="id" labelBody="Signee" keyToEdit="txt5" inputType="text" :data="doc.txt5" />
		
		<ModalUpgradePlan :id="'modal-upgrade'"/>
		<Flash ref="show"/>
	</div>
</template>

<script>
	import {eventBus} from '@/main'
	// Modals
	import ModalUpgradePlan from "@/components/Modals/ModalUpgradePlan";
	// Atoms
	import Flash from "@/components/Atoms/Flash";
	import ColorPicker from '@/components/Atoms/ColorPicker'
	import InputField from '@/components/Atoms/InputField'
	import SelectField from '@/components/Atoms/SelectField'
	import FileUploader from '@/components/Atoms/FileUploader'
	// Organisms
	import Markdown from "@/components/Organisms/Markdown";
	// Mixins
	import editor from '@/mixins/editor';
	import apiCalls from '@/mixins/apiCalls';

	export default {
		name: 'Certificate',
		mixins:[editor, apiCalls],
		props:{
			doc:{ type: Object },
			id:{ type: String },
			userInfo: { type: Array }
		},
		data() {
			return {
				objFile: null,
				validFileMsg_logo: 'Upload Image',
				invalidFile: null,
				newElem: "",
				cacheTimer: null,

				color1: {hex: this.doc.color1 || "#21405b"},
				color2: {hex: this.doc.color2 || "#ffffff"},
				color3: {hex: this.doc.color3 || "#A2551D"},

				fontSizes: [
					{ title: '18px', value: 18 },
					{ title: '20px', value: 20 },
					{ title: '24px', value: 24 },
					{ title: '28px', value: 28 },
					{ title: '32px', value: 32 },
					{ title: '56px', value: 56 },
				],
				show_ed_reference: JSON.parse(this.doc.show_ed_reference)
			}
		},
		components:{
			Flash,
			Markdown,
			ColorPicker,
			InputField,
			SelectField,
			FileUploader,
			ModalUpgradePlan
		},
		methods: {
			checkFontSizeValue(key, font_size) {
				if (font_size <= 13) {
					if (key == 'size1') {
						this.doc.size1 = 13
					} else if (key == 'size2') {
						this.doc.size2 = 13
					} else {
						this.doc.size3 = 13
					}

					font_size = 13
					this.docLazyUpdate({[key]: `${font_size}`}, this.id)
				} else {
					this.docLazyUpdate({[key]: `${font_size}`}, this.id)
				}
			}
		},

		mounted() {
			eventBus.$on('saveEditorChanges', (value) => {
				this.doc.markdown1 = value;
			});
		}
	}
</script>
<style scoped lang="scss">
	.two-fields-one-row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 2rem;
		margin-top: 6px;
	}
</style>
