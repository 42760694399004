<template>
	<div class="home h-100">
		<Editor/>
	</div>
</template>

<script>
	// @ is an alias to /src
	import Editor from '@/components/Editor.vue'

	export default {
		name: 'Home',
		components: {
			Editor
		},
		data() {
			return {
			}
		},
		head: {
			link() {
				return [
					{ rel: 'canonical', href: `https://elegantdoc.com/editor/${this.$route.params.id}`},
				]
			},
		},
		mounted() {
		}
	}
</script>
