<template>
    <div>
        <b-modal :id="id" 
                class="modal"
                hide-footer
				hide-header
				size="xl"
				body-class="modal-main">
            <a href="#" class="close-btn" @click.prevent="$bvModal.hide(id)"></a>
            <div>
				<h2 v-if="$route.query.payment == 'success'">
                    <span v-if="userInfo.length > 0">Congrats! You've just subscribed to <b>{{ userInfo[0].plan }}</b> plan</span>
                    <span v-else="userInfo.length > 0">Congrats! Your plan subscribtion has been successfully completed</span>
                </h2>
				<p v-if="$route.query.payment == 'success'">Now you can use ElegantDoc with all the package features that it provides.</p>
                <h2 v-if="$route.query.payment !== 'success'">Payment couldn't be completed!</h2>
				<p v-if="$route.query.payment !== 'success'">Something went wrong, please try again later...</p>
            </div>
        </b-modal>
		<Flash ref="show" />
    </div>
</template>

<script>
import Flash from '@/components/Atoms/Flash'
import apiCalls from '@/mixins/apiCalls';

export default {
    props: {
        id: {
            type: String,
            default: 'modal'
        }
    },
    data() {
        return {
            userInfo: []
        }
    },
	components: {
		Flash
	},
    mixins: [apiCalls],
    async mounted() {
        if (localStorage.accessToken) {
            this.userInfo = await this.getUser(localStorage.getItem('EDuserID'))
        }
    }
}
</script>

<style lang="scss">
	@import "@/assets/scss/modals/modal-upgrade.scss";
</style>