<template>
    <nav class="navbar-shown">
        <div class="inner-container">
            <a href="/">
                <img class="nav-logo" src="@/assets/images/logo.png" alt="Logo">
                ElegantDoc.com
            </a>
            <div class="nav_links">
                <a href="/blog/page/about">About Us</a>
                <a href="/blog">Blog</a>
                <router-link to="/docs">Docs</router-link>
                <router-link to="/billing">Billing</router-link>
                <a href="/blog/page/affiliates">Affiliates</a>
                <div v-if="ls">
                    <button @click.prevent="logout()" class="logout-button">Log out</button>
                </div>
                <div v-else style="color: #fff;">
                    <router-link to="/signup">Sign Up</router-link>
                    <router-link to="/login" class="login-button">Log In</router-link>
                </div>
            </div>
            <div class="hamburger-menu" @click="toggleNav()">
                <div class="line line-1"></div>
                <div class="line line-2"></div>
                <div class="line line-3"></div>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        data() {
            return {
                ls: localStorage.accessToken,
            }
        },
        methods: {
            logout() {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('EDuserID')
                this.$router.go(`/login`)
            },
            toggleNav() {
                if(document.querySelector('nav').classList.contains('navbar-shown')) {
                    document.querySelector('nav').classList.remove('navbar-shown')
                } else {
                    document.querySelector('nav').classList.add('navbar-shown')
                }
            }
        },
        mounted() {
            setTimeout(() => {
                if (localStorage.accessToken) {
                    this.ls = true
                } else {
                    this.ls = false
                }
            }, 250)
        }
    }
</script>

<style>
    nav {
        background-color: #192a56;
    }
    .inner-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;
        max-width: 1296px;
        margin: 0 auto;
    }
    @media (max-width: 1399px) {
        .inner-container {
            max-width: calc(1140px - (1.5rem * 0.5));
        }
    }
    @media (max-width: 1199px) {
        .inner-container {
            max-width: calc(936px - (1.5rem * 0.5));
        }
    }
    @media (max-width: 991px) {
        .inner-container {
            max-width: calc(696px - (1.5rem * 0.5));
        }
    }
    @media (max-width: 767px) {
        .inner-container {
            max-width: calc(516px - (1.5rem * 0.5));
        }
    }
    @media (max-width: 530px) {
        .inner-container {
            max-width: 90%;
        }
    }
    .nav-logo {
        height: 40px;
    }

    .nav_links {
        display: flex;
        align-items: center;
        /* column-gap: 1rem; */
        z-index: 2;
    }

    .nav_links a,
    .logout-button {
        color: #fff;
        text-decoration: none;
        background: none;
        border: none;
        outline: none;
        padding: 9.6px 16px;
    }
    .login-button {
        margin-left: 1rem;
    }
    .nav_links a:hover,
    .logout-button:hover {
        color: #5980f3;
    }
    .nav_links .router-link-exact-active {
        color: #fff !important;
        background-color: #2192EF;
        padding: .6rem 1rem;
        border-radius: .3rem;
    }
    .nav_links .router-link-exact-active:first-of-type {
        margin-right: 1rem;
    }
    .nav_links .router-link-exact-active:last-of-type {
        margin-left: 1rem;
    }

    nav .inner-container > a {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        height: auto;
        padding: 0;
        font-weight: 400 !important;
        font-size: 18px;
        color: #fff !important;
        text-decoration: none;
    }
    .hamburger-menu {
        display: none;
        flex-direction: column;
        align-items: center;
        row-gap: .5rem;
        cursor: pointer;
    }
    .hamburger-menu .line {
        width: 30px;
        height: 2px;
        background-color: #fff;
    }

    @media (max-width: 1000px) {
        nav .nav_links {
            position: absolute;
            left: 0%;
            top: 72px;
            background-color: #192a56;
            border-bottom: 2px solid #fff;
            flex-direction: column;
            width: 100%;
            align-items: flex-start;
        }
        nav .nav_links > * {
            width: calc(696px - (1.5rem * 0.5));
            margin: 0 auto;
        }
        nav .nav_links a,
        .logout-button {
            padding: .6rem 0;
            display: block;
        }
        .logout-button {
            width: 100%;
            text-align: left;
        }
        .login-button {
            margin-left: 0;
        }
        .nav_links .router-link-exact-active:first-of-type {
            margin-right: auto;
        }
        .nav_links .router-link-exact-active:last-of-type {
            margin-left: auto;
        }
        .hamburger-menu {
            display: flex;
        }
    }
    @media (max-width: 767px) {
        nav .nav_links > * {
            width: calc(516px - (1.5rem * 0.5));
        }
    }
    @media (max-width: 530px) {
        nav .nav_links > * {
            width: 90%;
        }
    }
    @media (min-width: 1000px) {
        nav .nav_links {
            display: flex !important;
        }
    }
    .navbar-shown .nav_links{
        display: none;
    }
</style>