<template>
	<div class="flash-holder">
		<div
			v-for="(msg, i) in flashInfo"
			:key="i"
			:class="['alert', 'alert-' + msg.state]"
		>
			<p class="text-center">{{msg.message}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Flash',
		data() {
			return {
				flashInfo: [],
				time: 3000,
			}
		},
		methods: {
			showFlashSuccess(msg) {
				this.flashInfo.push({message: msg, state: "success"});
				setTimeout(() => {
					this.flashInfo.shift();
				}, this.time);
			},
			showFlashError(msg) {
				this.flashInfo.push({message: msg, state: "danger"});
				setTimeout(() => {
					this.flashInfo.shift();
				}, this.time);
			},
			showFlashWarning(msg, time) {
				this.flashInfo.push({message: msg, state: "warning"});
				setTimeout(() => {
					this.flashInfo.shift();
				}, time);
			},
		},
	}
</script>
<style scoped lang="scss">
	.flash-holder {
		position: fixed;
		top: 100px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		flex-direction: column;
		max-width: 500px;
		z-index: 1060;
		pointer-events: none;

		p {
			margin-bottom: 0;
		}
	}
</style>
