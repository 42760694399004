<template>
  <div id="app" class="h-100">
    <Navbar v-if="!showNav" />
    <router-view/>
  </div>
</template>

<script>
  import Navbar from '@/components/Navbar'

  export default {
    components: {Navbar},
    computed:{
      showNav(){
        return this.$route.name == 'Home'
    }
}
  }
</script>

<style lang="scss">
	/*@import "@/assets/scss/styles";*/
</style>
