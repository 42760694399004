<template>
	<div class="login-page">
		<div class="custom-container">
			<form class="form" @submit.prevent="signup($event)">
				<div class="title">
					Signup
				</div>
				<div class="sub-title">
					We'll send you an email with a login link
				</div>
				<div class="form-group">
					<label class="form-label">Email</label>
					<input ref="email_input" @input="typing" required v-model="email" class="form-control" type="text" placeholder="Email"/>
				</div>
				<button ref="submit_btn" type="submit" class="btn btn-submit">Signup</button>
				<p :class="alert_show ? 'alert-p alert-p-not' : 'alert-p'">Check your email for a link. Please follow it to signup to ElegantDoc</p>
				<p style="margin: 1.5rem 0 0;">Already have an account? <router-link to="/login">Log in</router-link></p>
			</form>

		</div>
		<Flash ref="show"/>
	</div>
</template>

<script>
	import Flash from "../components/Atoms/Flash";
	import {api} from "../mixins/axios";
	export default {
		name: 'Signup',
		head: {
			link: [
				{ rel: 'canonical', href: 'https://elegantdoc.com/signup'},
			],
		},
		components: {
			Flash
		},
		data(){
			return{
				email: '',
				alert_show: false
			}
		},
		methods:{
			showAlert() {
				if (this.$refs.submit_btn.classList.contains('disabled')) {
					this.alert_show = true
				} else {
					this.alert_show = false
				}
			},
			typing() {
				this.$refs.submit_btn.classList.remove('disabled')

				this.showAlert()
			},
			async signup(e){
				let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
				if (e.target.querySelector('input').value.match(mailformat)) {
					try {
						let data = { email: this.email }
						
						let response = await api.post(`/user/create_or_login`, data);

						if (response.data.msg == 'ok') {
							this.$refs.submit_btn.classList.add('disabled')
							localStorage.setItem("EDuserID", response.data.user_id)
							this.showAlert()
						}

					} catch (e) {
						console.log(e);
						this.$refs.show.showFlashError(e)
					}
				} else {
					this.$refs.show.showFlashError('Please enter a valid email address.')
				}
			}
		},
		mounted() {
			this.$refs.email_input.focus()
			this.token = this.$route.query.token
            if(this.$route.query.token != undefined){
            	localStorage.setItem("accessToken", this.token)
			}
			if (localStorage.accessToken) {
				this.$router.push("/docs")
			}

		}
	}
</script>

<style scoped lang="scss">
	@import "@/assets/scss/components/login-signup";
	.alert-p {
		margin-top: 2rem;
		text-align: center;
		font-weight: 600;
		color: #fff;
		background-color: #009900;
		padding: .5rem;
		border-radius: .5rem;
		display: none;
	}
	.alert-p-not {
		display: block;
	}
</style>