<template>
    <div class="docs">
        <div class="container">
            <div class="title">
                <h1>My Documents</h1>
                <div class="form-group">
                    <span><font-awesome-icon icon="fa-solid fa-magnifying-glass" /></span>
                    <input type="text" placeholder="Search document" @input="searchDoc($event, docs)" >
                </div>
            </div>

            <div
                v-if="loading"
                class="h-100 d-flex justify-content-center align-items-center"
            >
                <b-spinner class="spinner-big" />
            </div>
            <div v-else class="docs-list">
                <div class="docs-container">
                    <div>
                        <a href="#" @click.prevent="$bvModal.show('modal-new-doc')" class="add-doc" >
                            <img src="@/assets/images/add-docadd-doc.svg" alt="">
                            <h5>New Document</h5>
                        </a>
                    </div>

                    <div v-for="(item, index) in docs" :key="item.pid" class="doc-item">
                        <router-link :to="`/editor/${item.id}`" style="text-decoration: none;" class="go-to-doc-link">
                            <div class="doc-head">
                                <div @click.prevent="duplicateTemplate(`${item.template}`, item)" class="remove-doc">
                                    <img src="@/assets/images/duplicate-icon.svg" alt="">
                                </div>
                                <div @click.prevent="openConfirm(item.id, item.title)" class="remove-doc" >
                                    <img src="@/assets/images/delete-icon.svg" alt="">
                                </div>
                            </div>

                            <div class="doc-body">
                                <img :src="require('@/assets/images/'+item.template+'.svg')" style="height: 40px">
                                <div>
                                    <h5>{{ item.title }}</h5>
                                    <p>{{ item.template }}</p>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <ModalConfirm
            :docId="docId"
            :docTitle="docTitle"
            :id="'modal-confirm'"
            :confirm_message="`Are you sure you want to delete doc`"
        />
        <ModalNewDoc :id="'modal-new-doc'" />
        <thankYouModal :id="'thank-you'" />
        <Flash ref="show" />
    </div>
</template>

<script>
// @ is an alias to /src
import { eventBus } from "@/main";
// Atoms
import Flash from "@/components/Atoms/Flash";
// Modals
import ModalConfirm from "@/components/Modals/ModalConfirm.vue";
import ModalNewDoc from "@/components/Modals/ModalNewDoc.vue";
import thankYouModal from '@/components/Modals/thankYouModal'
// Mixins
import { api } from "@/mixins/axios";
import apiCalls from "@/mixins/apiCalls";


export default {
    name: "Docs",
    head: {
        link: [{ rel: "canonical", href: "https://elegantdoc.com/docs" }],
    },
    components: {
        ModalNewDoc,
        ModalConfirm,
        Flash,
        thankYouModal
    },
    mixins: [apiCalls],
    data() {
        return {
            docs: [],
            docId: "",
            docTitle: "",
            loading: true,
            doc_render_PDF: process.env.VUE_APP_API_URL || "/api0",
        };
    },
    methods: {
        openConfirm(id, title) {
            this.docId = id;
            this.docTitle = title;
            this.$bvModal.show("modal-confirm");
        },

        async getDocs() {
            if (!localStorage.accessToken) return;
            this.loading = true;

            try {
                let response = await api.post("/doc/search", {});

                if (response.data.msg == 'ok') {
                    this.loading = false;
                    this.docs = response.data.data;
                } else {
                    localStorage.removeItem('accessToken')
                    this.$router.push(`/login`)
                }
                
            } catch (e) {
                console.log(e);
                this.loading = false;
            }
        }
    },
    mounted() {
        if (localStorage.accessToken) {
            this.getDocs();
        }
        eventBus.$on("docDeleted", () => {
            this.getDocs();
        });

        if (this.$route.query.payment) {
            setTimeout(() => this.$bvModal.show('thank-you'), 500)
        } else return
    },
};
</script>

<style scoped lang="scss">
.docs {
    padding: 1rem 1rem 3rem;
}
.docs-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
    padding: 0 !important;
    place-content: center;
}
@media (max-width: 767px) {
    .docs-container > div {
        width: 300px;
        min-height: 236px;
        margin: 0 auto;
    }
}

.title {
    margin-bottom: 2rem;
    margin-top: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title h1 {
    font-weight: 600;
    font-size: 38px;
}
.title .form-group {
    background-color: #EFF2F9;
    width: 45%;
}
.title .form-group input {
    background-color: transparent;
    border: none;
    outline: none;
    height: 54px;
    width: 90%;
    padding-left: 1rem;
}
.title .form-group > span {
    margin-left: 1rem;
    color: #2192EF;
}
@media (max-width: 991px) {
    .title {
        flex-direction: column;
    }
    .title .form-group { width: 100%; margin-top: 1rem; }
}

.doc-item {
    position: relative;
    border: 2px solid #ccc;
    padding: 1rem;
    height: 240px;
    background-color: #FCFCFC;
}
.doc-item:hover {
    background-color: #fff;
    box-shadow: .05rem .05rem .5rem rgba(0, 0, 0, .1);
}
.go-to-doc-link {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.doc-head {
    position: absolute;
    top: 10px;
    right: 15px;
    min-height: 46px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    img {
        width: 20px;
    }
}

.doc-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0 1rem;
    color: #000;
    text-align: center;
}
.doc-body h5 {
    margin-bottom: .5rem;
    overflow-wrap: anywhere;
}
.doc-body p {
    color: #2192EF;
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
}

.remove-doc {
    cursor: pointer;

    &:hover {
        color: #000;
    }
}

.add-doc {
    border: 2px dashed #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 1rem;
    gap: 10px;
    text-decoration: none;
    line-height: 1;
    height: 240px;
    font-size: 85px;
    color: #273c75;
    height: 236px;

    &:hover {
        color: #000;
    }
}
.docs-container > div:first-of-type:has(~ .doc-item) {
    height: 100%;
}
</style>
