<template>
	<div class="">
		<b-modal :id="id"
				 class="modal"
				 hide-footer
				 hide-header
				 size="md"
		>
			<a href="#" class="close-btn" @click.prevent="$bvModal.hide(id)"></a>
			<h5 class="text-center mb-3">Are you sure?</h5>
			<p>If you confirm this you'll go back to the Free Plan and you won't be able to use the previous features.</p>
			<div class="btns">
				<button @click.prevent="$bvModal.hide(id), updateUserPlan()" class="btn btn-yes">Yes</button>
				<button class="btn btn-no" @click.prevent="$bvModal.hide(id)">No</button>
			</div>
		</b-modal>
		<Flash ref="show"/>
	</div>

</template>

<script>
	import Flash from "@/components/Atoms/Flash";
	import {api} from "@/mixins/axios";

	export default {
		name: 'FreePlanModalConfirm',
		components: {
			Flash
		},
		props:{
			id: {
				type: String,
				default: 'modal'
			},
			userInfo: {
				type: Array
			}
		},
		methods:{
			async updateUserPlan(){
				if (!localStorage.accessToken) return
				else {
					console.log();
					try {
						let response = await api.post(`/user/update`, {id: this.userInfo[0].id, plan: 'free'});
	
						if (response.data.msg == 'ok') {
							this.$router.push('/docs?payment=success')
						}
	
					} catch (e) {
						this.$router.push('/docs?payment=unsuccess')
					}
				}
			},

		}
	}
</script>

<style lang="scss">
	@import "@/assets/scss/modals/modal-confirm.scss";
</style>
