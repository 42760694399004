<template>
    <div>
        <b-modal :id="id" 
                class="modal"
                hide-footer
				hide-header
				size="xl"
				body-class="modal-main">
            <a href="#" class="close-btn" @click.prevent="$bvModal.hide(id)"></a>
            <h1>What is Markdown?</h1>
            <div class="markdown-instructions-container">
                <img class="arrow-img" src="@/assets/images/arrow.png" alt="">
                <div>
                    <p># This is a header</p>
                    <h1>This is a header</h1>
                </div>
                <div>
                    <p>Here we have some normal text, and also some *boldfaced text*.</p>
                    <p>Here we have some normal text, and also some <b>boldfaced text</b>.</p>
                </div>
                <div>
                    <p>## This is a subsection</p>
                    <h2>This is a subsection</h2>
                </div>
                <div>
                    <div class="lists">
                        <p>And here we have both _italic stuff_ and we have:</p>
                        <p>1. One list</p>
                        <p>2. That's beautiful</p>
                    </div>
                    <div class="lists">
                        <p>And here we have both <em>italic stuff</em> and we have:</p>
                        <ol>
                            <li>One list</li>
                            <li>That's beautiful</li>
                        </ol>
                    </div>
                </div>
                <div>
                    <div class="lists">
                        <p>Or maybe bullet points</p>
                        <p>* Yes we support</p>
                        <p>* that as</p>
                        <p>* well</p>
                    </div>
                    <div class="lists">
                        <p>Or maybe bullet points</p>
                        <ul>
                            <li>Yes we support</li>
                            <li>that as</li>
                            <li>well</li>
                        </ul>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            default: 'modal'
        },
    }
}
</script>

<style lang="scss">
	@import "@/assets/scss/modals/modal-upgrade.scss";
    
    .markdown-instructions-container {
        margin-top: 3rem;
        text-align: left;
        position: relative;
    }
    .markdown-instructions-container p { margin: 0; }
    .markdown-instructions-container > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        column-gap: 15rem;
        margin-top: 2rem;
    }
    .lists p {
        margin-bottom: .3rem;
    }
    .arrow-img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 200px;
    }
</style>