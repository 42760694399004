<template>
    <div class="form-group mb-3">
        <label class="form-label">{{ labelBody }}</label>
        <input @input="removeNonNumeric($event)" v-model="data_mutation" required class="company-name form-control" :type="inputType"
                :placeholder="labelBody">
        <span class="focus-border"></span>
		<Flash ref="show" />
    </div>
</template>

<script>
import apiCalls from "@/mixins/apiCalls";
import Flash from '@/components/Atoms/Flash'

export default {
    name: 'InputField',
    props: {
        id: { type: String },
        labelBody: { type: String },
        keyToEdit: { type: String },
        inputType: { type: String },
        data: { type: String },
		number: { type: Boolean }
    },
    data () {
        return {
            data_mutation: this.data,
        }
    },
	components: { Flash },
    mixins: [apiCalls],
	methods: {
		removeNonNumeric(e) {
			if (this.number) {
				var inputValue = this.data_mutation;
				var cleanedValue = inputValue.replace(/[^\d]/g,'');
				var currentValue = this.data_mutation;
				if(currentValue !== cleanedValue) {
					this.data_mutation = cleanedValue;
				} else {
					this.docLazyUpdate({[this.keyToEdit]: this.data_mutation}, this.id, e)
				}
			} else {
				this.docLazyUpdate({[this.keyToEdit]: this.data_mutation}, this.id, e)
			}
		}
	}
}
</script>

<style scoped lang="scss">
.form-group {
	position: relative;
}
.focus-border {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0;
	height: 2px;
	background-color: #2192EF;
	transition: 0.4s;
}
.form-control {
	outline: none;
	box-shadow: unset;
	padding: 12px 16px;
	height: 54px;
	border-bottom: 1px solid #ccc;
	border-radius: 4px;
	background-color: #EFF2F9;
	border: none;
	transition: 400ms ease-in-out border-radius;

	&:focus {
		outline: none;
		box-shadow: unset;
		border-radius: 4px 4px 0 0;
		background-color: #EFF2F9;
	}
}

.form-control:focus ~ .focus-border {
	width: 100%;
	transition: 0.4s;
}

.form-label {
	color: rgb(130, 139, 162);
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	margin-bottom: 6px;
	width: 100%;
}
</style>