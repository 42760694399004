var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('b-modal',{staticClass:"modal",attrs:{"id":_vm.id,"hide-footer":"","hide-header":"","size":"lg"}},[_c('a',{staticClass:"close-btn",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.hide(_vm.id)}}}),_c('h1',{staticClass:"title text-center"},[_vm._v("New Document")]),_c('p',{staticClass:"text-center"},[_vm._v("What type of Doc you want to create?")]),_c('div',{staticClass:"wrap-docs"},[_c('a',{staticClass:"doc-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.newDoc('report', {
				txt1: 'TEAM TIGER X',
				txt2: 'ANNUAL REPORT 2023',
				markdown1: _vm.default_report_markdown,
				color1: '#192a56',
				color2: '#FFFFFF',
				color3: '#ffffff',
				color4: '#5B3EB7',
				txt3: 'right_and_left',
				txt9: 'template_1',
				size1: '32',
				size2: '24',
				size3: '20',
				size4: '18',
				file1: 'https://elegantdoc.com/app/imgs/ttx-logo.png',
				file2: 'https://elegantdoc.com/app/imgs/ttx-logo.png',
				show_ed_reference: 'true'
			})}}},[_c('img',{attrs:{"src":require("@/assets/images/report.svg"),"alt":""}}),_c('span',[_vm._v("Report")])]),_c('a',{staticClass:"doc-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.newDoc('memo', {
				title: 'Elegant Memo',
				txt1: 'design_1',
				markdown1: _vm.default_memo_markdown,
				color1: '#192a56',
				color2: '#ffffff',
				color3: '#ffffff',
				color4: '#5B3EB7',
				txt5: 'STREET ADDRESS, CITY, STATE ZIP CODE',
				txt6: '+123456789',
				txt7: 'https://tigerteamx.com',
				txt8: 'example@gmail.com',
				txt9: 'Company Mission Memo',
				size1: '32',
				size2: '24',
				size3: '20',
				size4: '18',
				file1: 'https://elegantdoc.com/app/imgs/ttx-logo-white.png',
				show_ed_reference: 'true'
			})}}},[_c('img',{attrs:{"src":require("@/assets/images/memo.svg"),"alt":""}}),_c('span',[_vm._v("Memo")])]),_c('a',{staticClass:"doc-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.newDoc('cert', {
				title: 'Elegant Certificate',
				txt1: 'Intership',
				txt2: 'Certificate',
				txt3: 'Certificate to',
				txt4: 'Aaron Swartz',
				markdown1: 'The brightest and most honorable intern we ever had',
				txt5: 'Will Smith, Technical Manager, 1th July 2022',
				size1: '56',
				size2: '28',
				size3: '24',
				size4: '18',
				color1: '#000',
				color2: '#CBB08180',
				color3: '#A2551D',
				file1: 'https://elegantdoc.com/static/images/templates/certificate_medal.png',
				show_ed_reference: 'true'
			})}}},[_c('img',{attrs:{"src":require("@/assets/images/cert.svg"),"alt":""}}),_c('span',[_vm._v("Certificate")])]),_c('a',{staticClass:"doc-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.newDoc('invoice', {
				title: 'Elegant Invoice',
				txt1: 'Invoice',
				markdown1: '**Invoice to:**  \n\n Mr. Peter McDonald  \n 17 Springdale Rd  \n London  \n N16 9NS \n\nContact Peter McDonald  \npeter.wp.mcdonald@gmail.com',
				txt3: '100-13',
				txt4: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000 )).toISOString().split('T')[0],
				txt5: 10,
				txt6: '€',
				markdown2: `**Invoice from:** \n\n Ben Johnson \n\nContact information  \n+447911123456  \nben.johnson@realhandy.uk`,
				markdown3: '**Terms and conditions** \n\n Payment is due 14 days after the date of this invoice.  \nPlease pay by bankwire to: \n\n***Bank Name :*** Barclays \n\n***IBAN :***  GB29 NWBK 6016 1331 9268 19',
				color1: '#192a56',
				color2: '#ffffff',
				color3: '#ffffff',
				color4: '#5B3EB7',
				file1: 'https://elegantdoc.com/app/imgs/ttx-logo.png',
				show_ed_reference: 'true'
			})}}},[_c('img',{attrs:{"src":require("@/assets/images/invoice.svg"),"alt":""}}),_c('span',[_vm._v("Invoice")])]),_c('a',{staticClass:"doc-item",attrs:{"aria-disabled":"true"}},[_c('img',{attrs:{"src":require("@/assets/images/cert.svg"),"alt":""}}),_c('span',[_vm._v("Flyer")])])])]),_c('Flash',{ref:"show"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }