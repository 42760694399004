<template>
	<div class="">
		<b-modal :id="id"
				 class="modal"
				 hide-footer
				 hide-header
				 size="lg"
		>
			<a href="#" class="close-btn" @click.prevent="$bvModal.hide(id)"></a>
			<h1 class="title text-center">New Document</h1>
			<p class="text-center">What type of Doc you want to create?</p>
			<div class="wrap-docs">
				<a class="doc-item" href="#" @click.prevent="newDoc('report', {
					txt1: 'TEAM TIGER X',
					txt2: 'ANNUAL REPORT 2023',
					markdown1: default_report_markdown,
					color1: '#192a56',
					color2: '#FFFFFF',
					color3: '#ffffff',
					color4: '#5B3EB7',
					txt3: 'right_and_left',
					txt9: 'template_1',
					size1: '32',
					size2: '24',
					size3: '20',
					size4: '18',
					file1: 'https://elegantdoc.com/app/imgs/ttx-logo.png',
					file2: 'https://elegantdoc.com/app/imgs/ttx-logo.png',
					show_ed_reference: 'true'
				})">
					<img src="@/assets/images/report.svg" alt="">
					<span>Report</span>
				</a>
				<a class="doc-item" href="#" @click.prevent="newDoc('memo', {
					title: 'Elegant Memo',
					txt1: 'design_1',
					markdown1: default_memo_markdown,
					color1: '#192a56',
					color2: '#ffffff',
					color3: '#ffffff',
					color4: '#5B3EB7',
					txt5: 'STREET ADDRESS, CITY, STATE ZIP CODE',
					txt6: '+123456789',
					txt7: 'https://tigerteamx.com',
					txt8: 'example@gmail.com',
					txt9: 'Company Mission Memo',
					size1: '32',
					size2: '24',
					size3: '20',
					size4: '18',
					file1: 'https://elegantdoc.com/app/imgs/ttx-logo-white.png',
					show_ed_reference: 'true'
				})">
					<img src="@/assets/images/memo.svg" alt="">
					<span>Memo</span>
				</a>
				<a class="doc-item" href="#" @click.prevent="newDoc('cert', {
					title: 'Elegant Certificate',
					txt1: 'Intership',
					txt2: 'Certificate',
					txt3: 'Certificate to',
					txt4: 'Aaron Swartz',
					markdown1: 'The brightest and most honorable intern we ever had',
					txt5: 'Will Smith, Technical Manager, 1th July 2022',
					size1: '56',
					size2: '28',
					size3: '24',
					size4: '18',
					color1: '#000',
					color2: '#CBB08180',
					color3: '#A2551D',
					file1: 'https://elegantdoc.com/static/images/templates/certificate_medal.png',
					show_ed_reference: 'true'
				})">
					<img src="@/assets/images/cert.svg" alt="">
					<span>Certificate</span>
				</a>
				<!-- <a class="doc-item" href="#" @click.prevent="newDoc('flyer')"> Flyer </a> -->
				<a class="doc-item" href="#" @click.prevent="newDoc('invoice', {
					title: 'Elegant Invoice',
					txt1: 'Invoice',
					markdown1: '**Invoice to:**  \n\n Mr. Peter McDonald  \n 17 Springdale Rd  \n London  \n N16 9NS \n\nContact Peter McDonald  \npeter.wp.mcdonald@gmail.com',
					txt3: '100-13',
					txt4: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000 )).toISOString().split('T')[0],
					txt5: 10,
					txt6: '€',
					markdown2: `**Invoice from:** \n\n Ben Johnson \n\nContact information  \n+447911123456  \nben.johnson@realhandy.uk`,
					markdown3: '**Terms and conditions** \n\n Payment is due 14 days after the date of this invoice.  \nPlease pay by bankwire to: \n\n***Bank Name :*** Barclays \n\n***IBAN :***  GB29 NWBK 6016 1331 9268 19',
					color1: '#192a56',
					color2: '#ffffff',
					color3: '#ffffff',
					color4: '#5B3EB7',
					file1: 'https://elegantdoc.com/app/imgs/ttx-logo.png',
					show_ed_reference: 'true'
				})">
					<img src="@/assets/images/invoice.svg" alt="">
					<span>Invoice</span>
				</a>
				<!-- <a class="doc-item" aria-disabled="true"> Invoice </a> -->
				<a class="doc-item" aria-disabled="true">
					<img src="@/assets/images/cert.svg" alt="">
					<span>Flyer</span>
				</a>
			</div>
		</b-modal>
		<Flash ref="show"/>
	</div>
</template>

<script>
	import Flash from "@/components/Atoms/Flash";
	import apiCalls from "@/mixins/apiCalls";

	export default {
		name: 'ModalNewDoc',
		components: {
			Flash
		},
		props: {
			id: {
				type: String,
				default: 'modal'
			},
		},
		mixins: [apiCalls],
		data() {
			return {
				default_report_markdown: `# Executive Summary

Tiger Team X is our company. This is our mission:

> We build software that makes your life easier while being
pleasant to use. Building it faster than you can imagine it.
[https://elegantdoc.com](https://elegantdoc.com/) and [https://duckist.com](https://duckist.com)

Everyone found 2022 to be difficult, but our team performed admirably on each and every assignment that was assigned to us.  
In 2022, we did a lot of consulting work, but this year we'll be making more of our own products and services.

We all had our troubles in 2022, but we overcame every roadblock that stood in our way. Every member of our team got along with the others incredibly well, and we always worked as a team. In the year that has passed, we brought so many incredible products, websites, and programming tools, but we anticipate becoming even greater and more productive in the year that lies ahead.

The year 2023 is going to be even better since we'll be taking on the "1 startup a month challenge" until we find the ideal product that benefits people. 

### Some important topics that are valuable of our team:
* Team performed well in 2022 despite difficult year
* Focus in 2023 shifted to creating own products and services
* Team will take on "1 startup a month challenge"
* Main mode of communication: Trello and Telegram
* Emphasis on clear communication and clarity in questions
* Team recognizes importance of fun and leisure time for motivation and job satisfaction. 

### How we communicate

Since we are a virtual woking company we do have our own ways and rules of communicating and working. We communicate primarily using Trello, and you can ping anyone using their handle in the comments. We also use Telegram for more urgent messages, but please avoid doing so outside working hours, and don’t expect people to be on their phones during their working hours.

It is crucial to follow The Guiding Principles while communicating as we communicate mainly async, meaning that you can’t expect someone to keep a chat with you ongoing. If your questions are clear (Clarity) and simple it will be easy to answer yes, no, or something else. If not, it will be a strain on your teammate’s time and energy.

If something additional is needed, it is always possible to arrange a meeting with the team member, but favour 1-1 with clear agendas.

### Our way of having fun.

Even though we run a serious company, we understand that
amusement is a necessity that must be done during working hours. We
are aware that occasionally it is advisable to allow our employees to
engage in certain free time activities as this will improve their mood
and motivation for their subsequent tasks. Therefore, entertainment is
very essential to us in all ways, and we are confident that this is how we
can make 2023 the best year with the best things we can bring and
have a wonderful working relationship with each college in our Team!
				`,
				default_memo_markdown: `# Memo 

 **To**: All Employees

 **From**: Managment

**Date**: February 1st, 2023

 **_CC_**: Embracing the Spirit of "Office Space"

We at Team Tiger X are inspired by the spirit of the classic film "Office Space"
and its message of workplace efficiency and job satisfaction. As such, we are
implementing new initiatives aimed at streamlining our processes and
improving our overall performance.

To that end, we will be updating our equipment and software, and providing
training sessions to ensure that all employees have the tools and knowledge
they need to succeed. We believe that these changes will not only improve
our efficiency, but also increase job satisfaction and morale.

Thank you for your cooperation and support as we embrace the spirit of
"Office Space" and work towards a brighter future.

Sincerely,  
Altin  
TeamTigerX Management

				`
			}
		}
	}
</script>

<style lang="scss">
	@import "@/assets/scss/modals/modal-new-doc.scss";
</style>
