<template>
    <div class="">
        <b-modal
            :id="id"
            class="modal"
            hide-footer
            hide-header
            size="xl"
            body-class="modal-main"
        >
            <a
                href="#"
                class="close-btn"
                @click.prevent="$bvModal.hide(id)"
            ></a>
            <h1 class="title text-center">This is a Business feature</h1>
            <p class="mb-3 text-center">To use please upgrade to business</p>

            <BillingCards />
        </b-modal>
        <Flash ref="show" />
    </div>
</template>

<script>
import Flash from "@/components/Atoms/Flash";
import Button from "@/components/Atoms/Button";
import BillingCards from "@/components/Molecules/BillingCards.vue";

export default {
        name: "ModalUpgrade",
        components: {
        Flash,
        Button,
        BillingCards
    },
    props: {
        id: {
            type: String,
            default: "modal",
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/modals/modal-upgrade.scss";
</style>
