<template>
    <div class="">
        <b-modal :id="id"
				 class="report-templates-modal"
				 hide-footer
				 hide-header
				 size="lg"
		>
        <a href="#" class="close-btn" @click.prevent="$bvModal.hide(id)"></a>
        <div class="report-templates-modal-inner">
            <h1 class="title text-center">Select Design</h1>
            <Carousel class="report-templates-container"
                    :perPageCustom="[[0, 1], [1000, 2]]"
                    :center-mode="true"
                    :navigationEnabled="true"
                    navigationNextLabel="&#x203A;"
                    navigationPrevLabel="P"
            >
                <Slide v-for="design in designs_src">
                    <img :src="design.src" @click="docUpdate({[design_key]: design.design_name})">
                </Slide>
            </Carousel>
        </div>
        
        </b-modal>
        <Flash ref="show" />
    </div>
</template>

<script>
    import {eventBus} from '@/main'
    import {api} from "@/mixins/axios";
    import { Carousel, Slide } from 'vue-carousel';
    import Flash from '@/components/Atoms/Flash'

    export default {
        name: 'Designs',
        components: {
            Carousel,
            Slide,
            Flash
        },
        props: {
            id: {
                type: String,
                default: 'modal'
            },
            template_id: {type: String},
            designs_src: {type: Array},
            design_key: {type: String}
        },
        methods: {
            async docUpdate(data) {
				try {
					data.id = this.template_id
					let response = await api.post("/doc/update", data);

					if (response.data.msg == 'ok') {
						eventBus.$emit('contextUpdated');
                        this.$bvModal.hide(this.id)
					}

				} catch (e) {
					console.log(e);
				}
			}
        }
    }
</script>

<style scoped>
    .report-templates-modal-inner {
        padding: 60px 50px 50px;
    }
	.title {
		font-weight: bold;
		font-size: 30px;
		line-height: 37px;
		letter-spacing: -0.02em;
		color: #323232;
		margin-bottom: 20px;
		text-align: center;
	}

    .VueCarousel-slide { transform: scale(.95); }
    
    /* .report-templates-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 1.5rem;
        justify-content: center;
    } */
    .report-templates-container div img {
        max-width: 300px;
        outline: 2px solid #323232;
        cursor: pointer;
    }
</style>