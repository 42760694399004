import Vue from 'vue'
import VueRouter from 'vue-router'
import Docs from '../views/Docs.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import Billing from '../views/Billing.vue'
import PageNotFound from '../views/PageNotFound.vue'
import landingPage from '../views/landingPage.vue'
import admin from '../views/admin.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/editor/:id',
		name: 'Home',
		component: Home
	},
	{
		path: '/login',
		name: 'Login',
		meta: {
			title: 'Login | ElegantDoc.com'
		},
		component: () => import('../views/Login.vue')
	},
	{
		path: '/signup',
		name: 'SignUp',
		meta: {
			title: 'Sign Up | ElegantDoc.com'
		},
		component: () => import('../views/SignUp.vue')
	},
	{
		path: '/docs',
		name: 'Docs',
		component: Docs,
		meta: {
			title: 'My Docs | ElegantDoc.com'
		}
	},
	{
		path: '/billing',
		name: 'Billing',
		component: Billing,
		meta: {
			title: 'My Billing | ElegantDoc.com'
		}
	},
	{
		path: '/admin',
		name: 'Admin',
		component: admin,
		meta: {
			title: 'admin | ElegantDoc.com'
		}
	},
	{
		path: '/lp',
		name: 'Landing Page',
		component: landingPage
	},
	{
		path: '/',
		name: 'Landing Page',
		component: landingPage
	},
	{
		path: '*',
		name: 'Page Not Found',
		component: PageNotFound
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})
router.beforeEach((to, from, next) => {
	const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
	if(nearestWithTitle) document.title = nearestWithTitle.meta.title;
	next();
});

router.beforeEach((to, from, next) => {
	const token = localStorage.getItem('accessToken')
	if (token || to.name === 'Login') {
		next()
	} else if (!token && to.path === '/signup') {
		next()
	} else if (!token && to.name === 'Landing Page') {
		next()
	} else if (!token && to.path === '/blog') {
		next()
	} else if (!token && to.path === '/billing') {
		next()
	} else {
		next({name: 'SignUp'})
	}
});

export default router
