<template>
    <div class="single-line mb-3">
		<div class="form-group">
			<input @input="updateLine({txt4: line.txt4, id: line.id})" v-model="line.txt4" required class="company-name form-control" type="text"
					placeholder="Description">
			<span class="focus-border"></span>
		</div>
		<div class="form-group">
			<input @input="removeNonNumeric({txt2: Number(line.txt2), id: line.id}, 'txt2', $event)" v-model="line.txt2" required class="company-name form-control" type="text"
					placeholder="Quantity">
			<span class="focus-border"></span>
		</div>
		<div class="form-group">
			<input @input="removeNonNumeric({txt3: Number(line.txt3), id: line.id}, 'txt3', $event)" v-model="line.txt3" required class="company-name form-control" type="text"
					placeholder="Price">
			<span class="focus-border"></span>
		</div>
		<Button class="item-btn" @click.native="switchWithBottom(lines, {id: line.id})">
			<font-awesome-icon icon="fa-solid fa-chevron-down" />
		</Button>
		<Button class="item-btn" @click.native="switchWithTop(lines, {id: line.id})">
			<font-awesome-icon icon="fa-solid fa-chevron-up" />
		</Button>
		<Button class="item-btn" @click.native="removeItem(lines, {id: line.id})">
			<font-awesome-icon icon="fa-solid fa-trash" />
		</Button>
		<Flash ref="show"/>
	</div>
</template>

<script>
import Flash from "@/components/Atoms/Flash";
import Button from '@/components/Atoms/Button'
import apiCalls from "@/mixins/apiCalls";

export default {
    name: 'InvoiceLine',
    props: {
        line: { type: Object },
		lines: { type: Array }
    },
	components: {
		Flash,
		Button
	},
    mixins: [apiCalls],
	methods: {
		removeNonNumeric(data, keyToChange, e) {
			var inputValue = e.target.value;
			var cleanedValue = inputValue.replace(/[^0-9.]/g,'');
			var currentValue = e.target.value;
			if(currentValue !== cleanedValue) {
				this.$set(this.line, keyToChange, cleanedValue)
				this.$set(data, keyToChange, cleanedValue)
			} else {
				this.updateLine(data)
			}
		}
	}
}
</script>

<style scoped lang="scss">
.form-group {
	position: relative;
}
.focus-border {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0;
	height: 2px;
	background-color: #2192EF;
	transition: 0.4s;
}
.form-control {
	background-color: rgb(239, 242, 249);
	outline: none;
	border: none;
	box-shadow: unset;
	border-radius: 3px;
	padding: 12px 16px;
	border-radius: 4px;

	&:focus {
		outline: none;
		box-shadow: unset;
		background-color: rgb(239, 242, 249);
	}
}

.form-control:focus ~ .focus-border {
	width: 100%;
	transition: 0.4s;
}
.single-line {
	display: grid;
	grid-template-columns: 1fr 4rem 4rem 2rem 2rem 2rem;
	column-gap: .4rem;
}
.single-line .form-group input {
	padding-left: .3rem;
	padding-right: .3rem;
}
.item-btn {
	color: #fff;
	font-size: 16px !important;
	letter-spacing: 0.02em;
	padding-left: .2rem;
	padding-right: .2rem;
	border-radius: .4rem !important;
	transition: 200ms ease-in-out background-color;

	&:hover {
		background-color: #1539af;
		color: #fff;
	}
}
.item-btn-disabled {
	background-color: rgba(39, 60, 117, 0.3);
	cursor: not-allowed;
	&:hover {
		background-color: rgba(39, 60, 117, 0.3);
	}
}
</style>