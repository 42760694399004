<template>
	<div class="settings-header">
		<span>Customize</span>
		<Button class="edit-design">
			<font-awesome-icon v-if="settings_collapsed" icon="fa-solid fa-chevron-down" />
			<font-awesome-icon v-else icon="fa-solid fa-chevron-up" />
		</Button>
		<Flash ref="show" />
	</div>
</template>

<script>
import Button from "@/components/Atoms/Button";
import apiCalls from "@/mixins/apiCalls";
import Flash from '@/components/Atoms/Flash'

export default {
    name: 'AdvancedOptions',
    props: {
        settings_collapsed: { type: Boolean }
    },
	components: { Button, Flash },
    mixins: [apiCalls]
}
</script>

<style scoped lang="scss">
    .settings-header {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		user-select: none;
		cursor: pointer;
		color: #273c75;
		font-weight: 600;

		&:hover {
			color: #5b3eb7;
		}
	}
	.edit-design{
		color: #273c75;
		background: transparent;
		
		svg {
			width: 1rem;
		}
	}
</style>