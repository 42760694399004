import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueHead from 'vue-head'
import axios from 'axios'

export const eventBus = new Vue();

import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import {faStar, faCheck, faArrowLeft, faEye, faEyeSlash, faImage, faTrash, faPlus, faPen, faChevronDown, faChevronUp, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(faStar, faCheck, faEye, faEyeSlash, faImage, faTrash, faPlus, faPen, faArrowLeft, faChevronDown, faChevronUp, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faMagnifyingGlass )

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(VueHead)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

Vue.config.errorHandler = function(err, vm, info) {
  console.error(err)
  const baseURL = process.env.VUE_APP_API_URL || "/api0"
  const data = {
      level: 'error',
      msg: JSON.stringify({ err: err.toString() || err }),
      url: window.location.href,
      info: info
  };

  axios.post(`${baseURL}/event/fe_error`, data)
  .then(res => {})
}