<template>
	<div class="">
		<InputField :id="id" labelBody="Title" keyToEdit="title" inputType="text" :data="doc.title" />
		<div class="two-fields-one-row">
			<InputField :id="id" labelBody="Invoice ID" keyToEdit="txt3" inputType="text" :data="doc.txt3" />
			<InputField :id="id" labelBody="Date" keyToEdit="txt4" inputType="date" :data="doc.txt4" />
			<InputField :id="id" labelBody="Tax" keyToEdit="txt5" inputType="text" :data="doc.txt5" :number="true" />
			<SelectField :id="id" labelBody="Currency" keyToEdit="txt6" :data="doc.txt6" :itemsToLoopThrough="currencies" />
		</div>
		<FileUploader :id="id" labelBody="Logo" keyToEdit='file1' inputType="file" inputID="file-input" :isFile="doc.file1" />
		<div v-if="userInfo[0].plan == 'free' || userInfo[0].plan == 'pro'" class="form-check mb-3" style="cursor: pointer" @click="$bvModal.show('modal-upgrade')">
			<input checked disabled class="form-check-input" type="checkbox" id="elegant_doc_reference">
			<label class="form-check-label" style="cursor: pointer" for="elegant_doc_reference">Show ElegantDoc Reference</label>
		</div>
		<label v-else for="elegant_doc_reference" class="form-check mb-3" style="cursor: pointer">
			<input ref="ed_reference_input" class="form-check-input" type="checkbox" v-model="show_ed_reference" id="elegant_doc_reference" @input="show_ed_reference = !show_ed_reference, docUpdate({show_ed_reference: JSON.stringify(show_ed_reference)}, id)">
			<span class="form-check-label" style="cursor: pointer; user-select: none;" for="elegant_doc_reference">Show ElegantDoc Reference</span>
		</label>
		<div class="two-fields-one-row color-picker">
			<ColorPicker :id="id" name="Primary Text Color" :data="color1" obj_key="color1" />
			<ColorPicker :id="id" name="Primary Background Color" :data="color2" obj_key="color2" />
			<ColorPicker :id="id" name="Secondary Text Color" :data="color3" obj_key="color3" />
			<ColorPicker :id="id" name="Secondary Background Color" :data="color4" obj_key="color4" />
		</div>
		<Markdown :id="id" markdown_num="markdown1" :data="doc.markdown1" labelBody="Invoice To" />
		<Markdown :id="id" markdown_num="markdown2" :data="doc.markdown2" labelBody="Invoice From" />
		<Markdown :id="id" markdown_num="markdown3" :data="doc.markdown3" labelBody="Terms" />
		<div class="lines-container mt-4">
			<h2>Invoice Lines</h2>
			<div class="single-lines-header">
				<label class="form-label">Description</label>
				<label class="form-label">Qty</label>
				<label class="form-label">Price</label>
			</div>
			<InvoiceLine v-for="line in lines" :line="line" :lines="lines" />
			<Button class="item-btn plus-btn" @click.native="addItem(lines, id)">
				<font-awesome-icon icon="fa-solid fa-plus" />
			</Button>
		</div>

		<ModalUpgradePlan :id="'modal-upgrade'"/>
        <Flash ref="show"/>
    </div>
</template>

<script>
	import {eventBus} from '@/main'
	// Modals
	import ModalUpgradePlan from "@/components/Modals/ModalUpgradePlan";
	// Atoms
	import Flash from "@/components/Atoms/Flash";
	import ColorPicker from "@/components/Atoms/ColorPicker";
	import Button from "@/components/Atoms/Button";
	import InputField from "@/components/Atoms/InputField";
	import InvoiceLine from "@/components/Molecules/InvoiceLine";
	import SelectField from "@/components/Atoms/SelectField";
	import FileUploader from "@/components/Atoms/FileUploader";
	// Organisms
	import Markdown from "@/components/Organisms/Markdown";
	// Mixins
	import editor from '@/mixins/editor'
	import apiCalls from "@/mixins/apiCalls";

	export default {
		name: 'Invoice',
		mixins: [editor, apiCalls],
		props: {
			doc: { type: Object },
			id: { type: String },
			userInfo: { type: Array }
		},
		data() {
			return {
				settings_collapsed: true,

				color1: {hex: this.doc.color1 || "#21405b"},
				color2: {hex: this.doc.color2 || "#ffffff"},
				color3: {hex: this.doc.color3 || "#ff7a00"},
				color4: {hex: this.doc.color4 || "#5b3eb7"},

				currency_value: this.doc.txt6 || '$',
				currentItem: 0,
				lines: this.doc.lines,

				currencies: [
					{ value: 'AED', title: 'AED (د.إ)' },
					{ value: 'AFN', title: 'AFN' },
					{ value: 'ALL', title: 'ALL (Lek)' },
					{ value: 'AMD', title: 'AMD' },
					{ value: 'ANG', title: 'ANG (ƒ)' },
					{ value: 'AOA', title: 'AOA (Kz)' },
					{ value: 'ARS', title: 'ARS ($)' },
					{ value: 'AUD', title: 'AUD ($)' },
					{ value: 'AWG', title: 'AWG (ƒ)' },
					{ value: 'AZN', title: 'AZN (ман)' },
					{ value: 'BAM', title: 'BAM (KM)' },
					{ value: 'BBD', title: 'BBD ($)' },
					{ value: 'BDT', title: 'BDT (Tk)' },
					{ value: 'BGN', title: 'BGN (лв)' },
					{ value: 'BHD', title: 'BHD' },
					{ value: 'BIF', title: 'BIF' },
					{ value: 'BMD', title: 'BMD ($)' },
					{ value: 'BND', title: 'BND ($)' },
					{ value: 'BOB', title: 'BOB ($b)' },
					{ value: 'BOV', title: 'BOV' },
					{ value: 'BRL', title: 'BRL (R$)' },
					{ value: 'BSD', title: 'BSD ($)' },
					{ value: 'BTN', title: 'BTN' },
					{ value: 'BWP', title: 'BWP (P)' },
					{ value: 'BYN', title: 'BYN (Br)' },
					{ value: 'CAD', title: 'CAD ($)' },
					{ value: 'CDF', title: 'CDF' },
					{ value: 'CHE', title: 'CHE' },
					{ value: 'CHF', title: 'CHF' },
					{ value: 'CHW', title: 'CHW' },
					{ value: 'CLF', title: 'CLF' },
					{ value: 'CLP', title: 'CLP ($)' },
					{ value: 'CNY', title: 'CNY (¥)' },
					{ value: 'COP', title: 'COP (p.)' },
					{ value: 'COU', title: 'COU' },
					{ value: 'CRC', title: 'CRC (₡)' },
					{ value: 'CUC', title: 'CUC' },
					{ value: 'CUP', title: 'CUP (₱)' },
					{ value: 'CVE', title: 'CVE' },
					{ value: 'CZK', title: 'CZK (Kč)' },
					{ value: 'DJF', title: 'DJF (CHF)' },
					{ value: 'DKK', title: 'DKK (kr)' },
					{ value: 'DOP', title: 'DOP (RD$)' },
					{ value: 'DZD', title: 'DZD' },
					{ value: 'EGP', title: 'EGP (E£)' },
					{ value: 'ERN', title: 'ERN' },
					{ value: 'ETB', title: 'ETB' },
					{ value: 'ETB', title: 'ETB' },
					{ value: '€', title: 'EUR (€)' },
					{ value: 'FJD', title: 'FJD ($)' },
					{ value: 'FKP', title: 'FKP (£)' },
					{ value: 'GBP', title: 'GBP (£)' },
					{ value: 'GEL', title: 'GEL' },
					{ value: 'GHS', title: 'GHS (GH¢)' },
					{ value: 'GIP', title: 'GIP (£)' },
					{ value: 'GMD', title: 'GMD' },
					{ value: 'GNF', title: 'GNF' },
					{ value: 'GTQ', title: 'GTQ (Q)' },
					{ value: 'GYD', title: 'GYD ($)' },
					{ value: 'HKD', title: 'HKD (HK$)' },
					{ value: 'HNL', title: 'HNL (L)' },
					{ value: 'HRK', title: 'HRK (kn)' },
					{ value: 'HTG', title: 'HTG' },
					{ value: 'HUF', title: 'HUF (Ft)' },
					{ value: 'IDR', title: 'IDR (Rp)' },
					{ value: 'ILS', title: 'ILS (₪)' },
					{ value: 'INR', title: 'INR (Rs)' },
					{ value: 'IQD', title: 'IQD' },
					{ value: 'IRR', title: 'IRR' },
					{ value: 'ISK', title: 'ISK (kr)' },
					{ value: 'JMD', title: 'JMD (J$)' },
					{ value: 'JOD', title: 'JOD' },
					{ value: 'JPY', title: 'JPY (¥)' },
					{ value: 'KES', title: 'KES (KSh)' },
					{ value: 'KGS', title: 'KGS (лв)' },
					{ value: 'KHR', title: 'KHR (៛)' },
					{ value: 'KMF', title: 'KMF' },
					{ value: 'KPW', title: 'KPW (₩)' },
					{ value: 'KRW', title: 'KRW (₩)' },
					{ value: 'KWD', title: 'KWD (ك)' },
					{ value: 'KYD', title: 'KYD ($)' },
					{ value: 'KZT', title: 'KZT (лв)' },
					{ value: 'LAK', title: 'LAK (₭)' },
					{ value: 'LBP', title: 'LBP (£)' },
					{ value: 'LKR', title: 'LKR (Rs)' },
					{ value: 'LRD', title: 'LRD ($)' },
					{ value: 'LSL', title: 'LSL' },
					{ value: 'LYD', title: 'LYD (LD)' },
					{ value: 'MAD', title: 'MAD' },
					{ value: 'MDL', title: 'MDL' },
					{ value: 'MGA', title: 'MGA' },
					{ value: 'MKD', title: 'MKD (ден)' },
					{ value: 'MMK', title: 'MMK' },
					{ value: 'MNT', title: 'MNT (₮)' },
					{ value: 'MOP', title: 'MOP' },
					{ value: 'MRU', title: 'MRU' },
					{ value: 'MUR', title: 'MUR (Rs)' },
					{ value: 'MVR', title: 'MVR' },
					{ value: 'MWK', title: 'MWK' },
					{ value: 'MXN', title: 'MXN ($)' },
					{ value: 'MXV', title: 'MXV' },
					{ value: 'MYR', title: 'MYR (RM)' },
					{ value: 'MZN', title: 'MZN (MT)' },
					{ value: 'NAD', title: 'NAD (N$)' },
					{ value: 'NGN', title: 'NGN (₦)' },
					{ value: 'NIO', title: 'NIO (C$)' },
					{ value: 'NOK', title: 'NOK (kr)' },
					{ value: 'NPR', title: 'NPR (Rs)' },
					{ value: 'NZD', title: 'NZD ($)' },
					{ value: 'OMR', title: 'OMR' },
					{ value: 'PAB', title: 'PAB (B/.)' },
					{ value: 'PEN', title: 'PEN (S/.)' },
					{ value: 'PGK', title: 'PGK' },
					{ value: 'PHP', title: 'PHP (₱)' },
					{ value: 'PKR', title: 'PKR (Rs)' },
					{ value: 'PLN', title: 'PLN (zł)' },
					{ value: 'PYG', title: 'PYG (Gs)' },
					{ value: 'QAR', title: 'QAR' },
					{ value: 'RON', title: 'RON (lei)' },
					{ value: 'RSD', title: 'RSD (Дин.)' },
					{ value: 'RUB', title: 'RUB (руб)' },
					{ value: 'RWF', title: 'RWF' },
					{ value: 'SAR', title: 'SAR' },
					{ value: 'SBD', title: 'SBD ($)' },
					{ value: 'SCR', title: 'SCR (Rs)' },
					{ value: 'SDG', title: 'SDG' },
					{ value: 'SEK', title: 'SEK (kr)' },
					{ value: 'SGD', title: 'SGD ($)' },
					{ value: 'SHP', title: 'SHP (£)' },
					{ value: 'SLL', title: 'SLL' },
					{ value: 'SOS', title: 'SOS (S)' },
					{ value: 'SRD', title: 'SRD ($)' },
					{ value: 'SSP', title: 'SSP' },
					{ value: 'STN', title: 'STN' },
					{ value: 'SVC', title: 'SVC ($)' },
					{ value: 'SYP', title: 'SYP (£)' },
					{ value: 'SZL', title: 'SZL' },
					{ value: 'THB', title: 'THB (฿)' },
					{ value: 'TJS', title: 'TJS' },
					{ value: 'TMT', title: 'TMT' },
					{ value: 'TND', title: 'TND (DT)' },
					{ value: 'TOP', title: 'TOP' },
					{ value: 'TRY', title: 'TRY' },
					{ value: 'TTD', title: 'TTD (TT$)' },
					{ value: 'TWD', title: 'TWD (NT$)' },
					{ value: 'TZS', title: 'TZS (TSh)' },
					{ value: 'UAH', title: 'UAH (₴)' },
					{ value: 'UGX', title: 'UGX (USh)' },
					{ value: 'UGX', title: 'UGX (USh)' },
					{ value: '$', title: 'USD ($)' },
					{ value: 'USN', title: 'USN' },
					{ value: 'UYI', title: 'UYI' },
					{ value: 'UYU', title: 'UYU ($U)' },
					{ value: 'UYW', title: 'UYW' },
					{ value: 'UZS', title: 'UZS (лв)' },
					{ value: 'VES', title: 'VES' },
					{ value: 'VND', title: 'VND (₫)' },
					{ value: 'VUV', title: 'VUV' },
					{ value: 'WST', title: 'WST' },
					{ value: 'XAF', title: 'XAF' },
					{ value: 'XAG', title: 'XAG' },
					{ value: 'XAU', title: 'XAU' },
					{ value: 'XBA', title: 'XBA' },
					{ value: 'XBB', title: 'XBB' },
					{ value: 'XBC', title: 'XBC' },
					{ value: 'XBD', title: 'XBD' },
					{ value: 'XCD', title: 'XCD ($)' },
					{ value: 'XDR', title: 'XDR' },
					{ value: 'XOF', title: 'XOF' },
					{ value: 'XPD', title: 'XPD' },
					{ value: 'XPF', title: 'XPF' },
					{ value: 'XPT', title: 'XPT' },
					{ value: 'XSU', title: 'XSU' },
					{ value: 'XTS', title: 'XTS' },
					{ value: 'XUA', title: 'XUA' },
					{ value: 'XXX', title: 'XXX' },
					{ value: 'YER', title: 'YER' },
					{ value: 'ZAR', title: 'ZAR' },
					{ value: 'ZAR', title: 'ZAR (R)' },
					{ value: 'ZMW', title: 'ZMW (ZK)' },
					{ value: 'ZWL', title: 'ZWL' },
				],
				show_ed_reference: JSON.parse(this.doc.show_ed_reference)
			}
		},
		components: {
			Flash,
			ColorPicker,
			Button,
			Markdown,
			InputField,
			InvoiceLine,
			SelectField,
			FileUploader,
			ModalUpgradePlan
		},
		mounted() {
			eventBus.$on('saveEditorChanges', (value) => {
				this.doc.body = value;
			});
		}
	}
</script>
<style scoped lang="scss">

	.two-fields-one-row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 2rem;
	}

	.lines-container > h2 {
		font-weight: 700;
	}
	.item-btn {
		color: #fff;
		font-size: 16px !important;
		letter-spacing: 0.02em;
		padding-left: .2rem;
		padding-right: .2rem;
		border-radius: .4rem !important;
		transition: 200ms ease-in-out background-color;

		&:hover {
			background-color: #1539af;
            color: #fff;
		}
	}
	.item-btn-disabled {
		background-color: rgba(39, 60, 117, 0.3);
		cursor: not-allowed;
		&:hover {
			background-color: rgba(39, 60, 117, 0.3);
		}
	}

	.single-lines-header {
		display: grid;
		grid-template-columns: 1fr 4rem 4rem 2rem 2rem 2rem;
		column-gap: .4rem;
	}
	.single-lines-header .form-label {
		font-size: 14px;
		font-weight: 500;
	}

	.plus-btn {
		display: block;
		padding-left: 1rem;
		padding-right: 1rem;
		margin: 0 auto;
	}
</style>
