<template>
    <div>
        <header>
            <div class="inner-section">
                <div class="header-info">
                    <h6>
                        <span>
                            <img src="@/assets/images/orange-star.svg" alt="">
                            <img src="@/assets/images/orange-star.svg" alt="">
                            <img src="@/assets/images/orange-star.svg" alt="">
                            <img src="@/assets/images/orange-star.svg" alt="">
                            <img src="@/assets/images/orange-star.svg" alt="">
                        </span>
                        <span class="top-text">based on customer feedback</span>
                    </h6>
                    <h1>Make Impactful Documents in Seconds</h1>
                    <ul>
                        <li>Customize just the essentials</li>
                        <li>Stunning templates</li>
                        <li>Consistent results every time</li>
                    </ul>
                    <router-link to="/docs" class="header-btn">Create your free document</router-link>
                </div>
                <img src="@/assets/images/macbook-header.png" alt="">
            </div>
        </header>
        <section class="single-review">
            <div class="inner-section">
                <div class="card">
                    <div class="person-info">
                        <img src="@/assets/images/sarah-min.jpeg" alt="">
                        <div class="card-info">
                            <h4>Sarah</h4>
                            <p>CEO of a small SEO boutique in The USA</p>
                            <span>
                                <img src="@/assets/images/orange-star.svg" alt="">
                                <img src="@/assets/images/orange-star.svg" alt="">
                                <img src="@/assets/images/orange-star.svg" alt="">
                                <img src="@/assets/images/orange-star.svg" alt="">
                                <img src="@/assets/images/orange-star.svg" alt="">
                            </span>
                        </div>    
                    </div>
                    <div class="card-desc">
                        <p>Elegantdoc.com is a fantastic resource for anyone looking to create perfectly designed documents in a fast and simple way. The user-friendly interface allows me to customize my documents to fit my specific needs. The wide range of templates ensures that I can find the perfect design for any project and target audience. Now I spend less time formatting and more time focusing on the content of my documents.</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="docs-section">
            <div class="inner-docs-section">
                <h2>Create beautiful <span>documents</span> fast</h2>
                <p>The versatile online document software that will help you create a variety of documents, such as <strong>academic papers, presentable certificates, reports, invoices, or even proposals.</strong></p>
                <div class="docs-gallery">
                    <div class="row">
                        <img src="@/assets/images/lp-memo-1.svg" loading="lazy">
                        <img src="@/assets/images/lp-report-1.svg" loading="lazy">
                        <img src="@/assets/images/lp-cert-1.svg" loading="lazy">
                        <img src="@/assets/images/lp-report-2.svg" loading="lazy">
                        <img src="@/assets/images/lp-report-3.svg" loading="lazy">
                        <img src="@/assets/images/lp-invoice.svg" loading="lazy">
                        <img src="@/assets/images/lp-cert-1.svg" loading="lazy">
                        <img src="@/assets/images/lp-memo-1.svg" loading="lazy">
                    </div>
                    <div class="row">
                        <img src="@/assets/images/lp-report-2.svg" loading="lazy">
                        <img src="@/assets/images/lp-invoice.svg" loading="lazy">
                        <img src="@/assets/images/lp-report-3.svg" loading="lazy">
                        <img src="@/assets/images/lp-cert-1.svg" loading="lazy">
                        <img src="@/assets/images/lp-report-2.svg" loading="lazy">
                        <img src="@/assets/images/lp-report-1.svg" loading="lazy">
                        <img src="@/assets/images/lp-invoice.svg" loading="lazy">
                        <img src="@/assets/images/lp-report-2.svg" loading="lazy">
                    </div>
                </div>
            </div>
        </section>
        <section class="benefits">
            <div class="inner-section">
                <h2 class="title-level-2">Who is this for?</h2>
    
                <div class="wrap-benefits">
                    <div class="item-benefit">
                        <div class="wrap-img">
                            <img loading="lazy" class="img-fluid" src="@/assets/images/1-whos-this-for-min.jpg" alt="">
                        </div>
                        <div class="text"><strong>Legal professionals</strong> who need consistent output and know that the finishing touches often have the highest impact.</div>
                    </div>
                    <div class="item-benefit">
                        <div class="wrap-img">
                            <img loading="lazy" class="img-fluid" src="@/assets/images/3-whos-this-for-min.jpg" alt="">
                        </div>
                        <div class="text"><strong>Consultants</strong> who audit or guide and need consistent results for their collaborators and client base.</div>
                    </div>
                    <div class="item-benefit">
                        <div class="wrap-img">
                            <img loading="lazy" class="img-fluid" src="@/assets/images/2-whos-this-for-min.jpg" alt="">
                        </div>
                        <div class="text"><strong>Freelancers</strong> in need of quotes, invoices, and reports for their clients.</div>
                    </div>
                </div>
            </div>
		</section>
        <section class="docs-section">
            <div class="inner-docs-section">
                <h2>Comparison</h2>
                <p>In other words, consistently make beautifully designed templates in seconds without the need to design them again and again.</p>
                <img src="@/assets/images/comparison.png" style="width: 95%;max-width: 600px;">
            </div>
        </section>
        <section class="doc-types-section">
            <div class="inner-section">
                <div class="doc-types-wrapper">
                    <a class="doc-item" href="#" @click.prevent="newDoc('report', {
                        txt1: 'TEAM TIGER X',
                        txt2: 'ANNUAL REPORT 2023',
                        markdown1: default_report_markdown,
                        color1: '#192a56',
                        color2: '#FFFFFF',
                        color3: '#ffffff',
                        color4: '#5B3EB7',
                        txt3: 'right_and_left',
                        txt9: 'template_1',
                        size1: '32',
                        size2: '24',
                        size3: '20',
                        size4: '18',
                        file1: 'https://elegantdoc.com/app/imgs/ttx-logo.png',
                        file2: 'https://elegantdoc.com/app/imgs/ttx-logo.png'
                    })">
                        <img src="@/assets/images/report.svg" alt="">
                        <span>Reports</span>
                    </a>
                    <a class="doc-item" href="#" @click.prevent="newDoc('memo', {
                        title: 'Elegant Memo',
                        txt1: 'design_1',
                        markdown1: default_memo_markdown,
                        color1: '#192a56',
                        color2: '#ffffff',
                        color3: '#ffffff',
                        color4: '#5B3EB7',
                        txt5: 'STREET ADDRESS, CITY, STATE ZIP CODE',
                        txt6: '+123456789',
                        txt7: 'https://tigerteamx.com',
                        txt8: 'example@gmail.com',
                        txt9: 'Company Mission Memo',
                        size1: '32',
                        size2: '24',
                        size3: '20',
                        size4: '18',
                        file1: 'https://elegantdoc.com/app/imgs/ttx-logo-white.png'
                    })">
                        <img src="@/assets/images/memo.svg" alt="">
                        <span>Memos</span>
                    </a>
                    <a class="doc-item" href="#" @click.prevent="newDoc('cert', {
                        title: 'Elegant Certificate',
                        txt1: 'Intership',
                        txt2: 'Certificate',
                        txt3: 'Certificate to',
                        txt4: 'Aaron Swartz',
                        markdown1: 'The brightest and most honorable intern we ever had',
                        txt5: 'Will Smith, Technical Manager, 1th July 2022',
                        size1: '56',
                        size2: '28',
                        size3: '24',
                        size4: '18',
                        color1: '#000',
                        color2: '#CBB08180',
                        color3: '#A2551D',
                        file1: 'https://elegantdoc.com/static/images/templates/certificate_medal.png'
                    })">
                        <img src="@/assets/images/cert.svg" alt="">
                        <span>Presentable Certificates</span>
                    </a>
                    <a class="doc-item" href="#" @click.prevent="newDoc('invoice', {
                        title: 'Elegant Invoice',
                        txt1: 'Invoice',
                        markdown1: '**Invoice to:**  \n\n Mr. Peter McDonald  \n 17 Springdale Rd  \n London  \n N16 9NS \n\nContact Peter McDonald  \npeter.wp.mcdonald@gmail.com',
                        txt3: '100-13',
                        txt4: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000 )).toISOString().split('T')[0],
                        txt5: 10,
                        txt6: '€',
                        markdown2: `**Invoice from:** \n\n Ben Johnson \n\nContact information  \n+447911123456  \nben.johnson@realhandy.uk`,
                        markdown3: '**Terms and conditions** \n\n Payment is due 14 days after the date of this invoice.  \nPlease pay by bankwire to: \n\n***Bank Name :*** Barclays \n\n***IBAN :***  GB29 NWBK 6016 1331 9268 19',
                        color1: '#192a56',
                        color2: '#ffffff',
                        color3: '#ffffff',
                        color4: '#5B3EB7',
                        file1: 'https://elegantdoc.com/app/imgs/ttx-logo.png'
                    })">
                        <img src="@/assets/images/invoice.svg" alt="">
                        <span>Invoices</span>
                    </a>
                    <a class="doc-item" aria-disabled="true">
                        <img src="@/assets/images/cert.svg" alt="">
                        <span>Academic papers</span>
                    </a>
                </div>
                <div class="doc-types-info">
                    <h2>Elegant Docs Helps You</h2>
                    <ul>
                        <li>
                            <h6>Create visually appealing documents in minutes</h6>
                            <p>Simply insert all relevant content into a template that best suits your needs</p>
                        </li>
                        <li>
                            <h6>Improve your productivity</h6>
                            <p>Imagine what you and your team could accomplish during the time saved instead of designing and formatting documents</p>
                        </li>
                        <li>
                            <h6>Seamless file export</h6>
                            <p>Download your completed document to PDF</p>
                        </li>
                    </ul>
                    <router-link to="/docs" class="header-btn">Create your free document</router-link>
                </div>
            </div>
        </section>
        <section class="billing-section">
            <div class="inner-section">
                <h2>Pricing</h2>
                <BillingCards />
            </div>
        </section>
        <section class="customers-section">
            <div class="inner-section">
                <h2>What our customer say</h2>
                <div class="customers-singles-wrapper">
                    <div class="customer-single">
                        <div class="customer-personal-info">
                            <img src="@/assets/images/sarah-min.jpeg" alt="">
                            <div class="customer-info">
                                <h4>Sarah</h4>
                                <p>CEO of a small SEO boutique in The USA</p>
                            </div>
                        </div>
                        <div class="customer-opinion">
                            <div class="rating">
                                <img src="@/assets/images/orange-star.svg">
                                <img src="@/assets/images/orange-star.svg">
                                <img src="@/assets/images/orange-star.svg">
                                <img src="@/assets/images/orange-star.svg">
                                <img src="@/assets/images/orange-star.svg">
                            </div>
                            <p>Elegantdoc.com is a fantastic resource for anyone looking to create perfectly designed documents in a fast and simple way. The user-friendly interface allows me to customize my documents to fit my specific needs. The wide range of templates ensures that I can find the perfect design for any project and target audience. Now I spend less time formatting and more time focusing on the content of my documents.</p>
                        </div>
                    </div>
                    <div class="customer-single">
                        <div class="customer-personal-info">
                            <img src="@/assets/images/gary-min.jpeg" alt="">
                            <div class="customer-info">
                                <h4>Gary</h4>
                                <p>CTO in a medium-sized security firm in Hong Kong.</p>
                            </div>
                        </div>
                        <div class="customer-opinion">
                            <div class="rating">
                                <img src="@/assets/images/orange-star.svg">
                                <img src="@/assets/images/orange-star.svg">
                                <img src="@/assets/images/orange-star.svg">
                                <img src="@/assets/images/orange-star.svg">
                                <img src="@/assets/images/orange-star.svg">
                            </div>
                            <p>I have been using ElegantDoc.com to create beautiful, branded documents for my business and couldn't be happier! The platform is incredibly user-friendly, and it offers a range of customizable templates that make it easy to generate professional-looking documents in minutes. The best part is the convenience of being able to access and edit my documents from any device. I highly recommend this tool to anyone looking to elevate their business's document design.</p>
                        </div>
                    </div>
                    <div class="customer-single">
                        <div class="customer-personal-info">
                            <img src="@/assets/images/ian-min.jpeg" alt="">
                            <div class="customer-info">
                                <h4>Ian</h4>
                                <p>CEO in a larger typographic company in the United Kingdom.</p>
                            </div>
                        </div>
                        <div class="customer-opinion">
                            <div class="rating">
                                <img src="@/assets/images/orange-star.svg">
                                <img src="@/assets/images/orange-star.svg">
                                <img src="@/assets/images/orange-star.svg">
                                <img src="@/assets/images/orange-star.svg">
                                <img src="@/assets/images/orange-star.svg">
                            </div>
                            <p>As a busy entrepreneur, I am always looking for ways to save time and streamline my workflow. ElegantDoc.com has been a game-changer for me! It allows me to quickly and easily create professional-looking documents for my business without employing a designer. The customizable templates, make it easy to create branded reports, memos, and certificates in a snap, saving my team hours every week.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <footer>
            <div class="inner-section">
                <p>© Copyright 2023, All Rights Reserved</p>
                <div class="payment-methods">
                    <img src="@/assets/images/stripe.svg">
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import apiCalls from '@/mixins/apiCalls'
import BillingCards from '@/components/Molecules/BillingCards';

export default {
    components: {BillingCards},
    mixins: [apiCalls],
    data() {
        return {
            default_report_markdown: `# Executive Summary

Tiger Team X is our company. This is our mission:

> We build software that makes your life easier while being
pleasant to use. Building it faster than you can imagine it.
[https://elegantdoc.com](https://elegantdoc.com/) and [https://duckist.com](https://duckist.com)

Everyone found 2022 to be difficult, but our team performed admirably on each and every assignment that was assigned to us.  
In 2022, we did a lot of consulting work, but this year we'll be making more of our own products and services.

We all had our troubles in 2022, but we overcame every roadblock that stood in our way. Every member of our team got along with the others incredibly well, and we always worked as a team. In the year that has passed, we brought so many incredible products, websites, and programming tools, but we anticipate becoming even greater and more productive in the year that lies ahead.

The year 2023 is going to be even better since we'll be taking on the "1 startup a month challenge" until we find the ideal product that benefits people. 

### Some important topics that are valuable of our team:
* Team performed well in 2022 despite difficult year
* Focus in 2023 shifted to creating own products and services
* Team will take on "1 startup a month challenge"
* Main mode of communication: Trello and Telegram
* Emphasis on clear communication and clarity in questions
* Team recognizes importance of fun and leisure time for motivation and job satisfaction. 

### How we communicate

Since we are a virtual woking company we do have our own ways and rules of communicating and working. We communicate primarily using Trello, and you can ping anyone using their handle in the comments. We also use Telegram for more urgent messages, but please avoid doing so outside working hours, and don’t expect people to be on their phones during their working hours.

It is crucial to follow The Guiding Principles while communicating as we communicate mainly async, meaning that you can’t expect someone to keep a chat with you ongoing. If your questions are clear (Clarity) and simple it will be easy to answer yes, no, or something else. If not, it will be a strain on your teammate’s time and energy.

If something additional is needed, it is always possible to arrange a meeting with the team member, but favour 1-1 with clear agendas.

### Our way of having fun.

Even though we run a serious company, we understand that
amusement is a necessity that must be done during working hours. We
are aware that occasionally it is advisable to allow our employees to
engage in certain free time activities as this will improve their mood
and motivation for their subsequent tasks. Therefore, entertainment is
very essential to us in all ways, and we are confident that this is how we
can make 2023 the best year with the best things we can bring and
have a wonderful working relationship with each college in our Team!
			`,
            default_memo_markdown: `# Memo 

 **To**: All Employees

 **From**: Managment

**Date**: February 1st, 2023

 **_CC_**: Embracing the Spirit of "Office Space"

We at Team Tiger X are inspired by the spirit of the classic film "Office Space"
and its message of workplace efficiency and job satisfaction. As such, we are
implementing new initiatives aimed at streamlining our processes and
improving our overall performance.

To that end, we will be updating our equipment and software, and providing
training sessions to ensure that all employees have the tools and knowledge
they need to succeed. We believe that these changes will not only improve
our efficiency, but also increase job satisfaction and morale.

Thank you for your cooperation and support as we embrace the spirit of
"Office Space" and work towards a brighter future.

Sincerely,  
Altin  
TeamTigerX Management

			`
        }
    }
}
</script>

<style>
/* HEADER */
header {
    background-color: #192a56;
    padding: 5rem 0 4rem 0;
    position: relative;
    overflow: hidden;
    min-height: 75vh;
}
.inner-section {
    max-width: 1296px;
    margin: 0 auto;
}
@media (min-height: 1050px) {
    header {
        min-height: 700px;
    }
}
@media (max-width: 1399px) {
    .inner-section {
        max-width: calc(1140px - (1.5rem * 0.5));
    }
}
@media (max-width: 1199px) {
    .inner-section {
        max-width: calc(936px - (1.5rem * 0.5));
    }
}
@media (max-width: 991px) {
    .inner-section {
        max-width: calc(696px - (1.5rem * 0.5));
    }
}
@media (max-width: 767px) {
    .inner-section {
        max-width: calc(516px - (1.5rem * 0.5));
    }
}
@media (max-width: 530px) {
    .inner-section {
        max-width: 90%;
    }
}
@media (max-width: 440px) {
    header {
        padding: 4rem 0;
    }
}

.header-info {
    max-width: 550px;
    color: #fff;
}
.header-info h1 {
    font-size: 50px;
}
.header-info h6 {
    text-transform: uppercase;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.header-info h6 .top-text {
    font-size: 14px;
    margin-left: .5rem;
    transform: translateY(1px);
}
.header-info h6 img:not(:last-of-type) {
    margin-right: .2rem;
}
.header-info ul {
    margin-top: 1.5rem;
}
.header-info ul li {
    list-style-image: url('../assets/images/green-tick.svg');
    margin-top: 1rem;
    font-weight: 500;
}
.header-btn {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    background-color: #046FC8;
    margin-top: 2rem;
    font-size: 16px !important;
    padding: 1rem 1.5rem;
    border-radius: .5rem !important;
    font-weight: 600;
}
.header-btn:hover {
    background-color: #2192EF;
    color: #fff
}
@media (max-width: 440px) {
    .header-info h1 {
        font-size: 40px;
    }
    .header-info h6 {
        flex-direction: column;
        align-items: flex-start;
        row-gap: .5rem;
    }
    .header-info h6 .top-text {
        margin-left: 0;
    }
}
@media (max-width: 408px) {
    .header-info h1 {
        font-size: 35px;
    }
}

header .inner-section {
    display: grid;
    grid-template-columns: 1fr 600px;
    gap: 2rem;
}

header .inner-section > img {
    pointer-events: none;
    user-select: none;
}
@media (max-width: 1199px) {
    header .inner-section {
        grid-template-columns: 1fr;
    }
    header .inner-section > img {
        display: none;
    }
}
/* HEADER */

/* SINGLE REVIEW */
.single-review {
    background-color: #223568;
    color: #fff;
    padding: 4rem 0;
}
.single-review .card,
.single-review .card .person-info {
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
}
.single-review .card .card-desc {
    flex-basis: 90%;
    border-left: 1px solid rgba(255, 255, 255, .4);
    padding-left: 2rem;
} 
.single-review .card .person-info > img {
    width: 83px;
    border-radius: 50%;
    object-fit: cover;
}
.single-review .card .person-info h4 {
    font-weight: 600;
}
@media (max-width: 767px) {
    .single-review .card {
        flex-direction: column;
    }
    .single-review .card,
    .single-review .card .person-info {
        gap: 1rem
    }
    .single-review .card-info p {
        margin-bottom: 0;
    }
}
/* SINGLE REVIEW */

/* DOCS SECTION */
.docs-section {
    text-align: center;
    padding: 4rem 0;
}
.docs-section .inner-docs-section {
    max-width: 1296px;
    margin: 0 auto;
}
.docs-section h2 {
    font-size: 38px;
}
.docs-section h2 span {
    color: #2192EF;
}
.docs-section p {
    color: #4F4F4F;
    max-width: 700px;
    margin: 2rem auto 2.5rem auto;
}
@media (max-width: 760px) {
    .docs-section .inner-docs-section h2 {
        margin: 0 auto;
        margin-bottom: .5rem;
    }
    .docs-section .inner-docs-section p {
        width: 95%;
    }
}
@media (max-width: 450px) {
    .docs-section h2 {
        font-size: 30px;
    }
}
@media (max-width: 1460px) {
    .docs-section .inner-docs-section {
        max-width: 100%;
    }
    .docs-section .docs-gallery {
        overflow: hidden
    }
}
.docs-section .docs-gallery .row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 1rem;
    margin-top: 1.5rem;
}
.docs-section .docs-gallery .row img {
    height: 187px;
    width: auto;
    padding-left: 0;
    padding-right: 0;
}
/* DOCS SECTION */

/* BENEFITS */
.benefits {
    padding: 4rem 0;
}
.benefits .title-level-2 {
    font-size: 38px;
    margin-bottom: 30px;
    text-align: center;
}
.benefits .wrap-benefits {
    display: flex;
    gap: 25px;
    justify-content: center;
    flex-wrap: wrap;
}
.benefits .item-benefit {
    max-width: 300px;
}
.benefits .item-benefit .text {
    margin-top: 15px;
}
@media (max-width: 450px) {
    .benefits .title-level-2 {
        font-size: 30px;
    }
}
/* BENEFITS */

/* DOC TYPES */
.doc-types-section {
    padding: 4rem 0;
}
.doc-types-section .inner-section {
    display: flex;
    align-items: flex-start;
    gap: 5rem
}
.doc-types-section .doc-types-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    width: 100%;
    max-width: 485px;
}
.doc-types-section .doc-item{
    border: 1px solid #ccc;
    padding: 1rem;
    text-decoration: none;
    color: #000;
    font-weight: 500;
    text-align: left;
    background-color: #F5F5F5;
}
.doc-types-section .doc-item:hover{
    border: 1px solid #5b3eb7;
    color: #000;
}
.doc-types-section .doc-item img {
    height: 30px;
    margin-right: 1rem;
    user-select: none;
    pointer-events: none;
}
.doc-types-section .doc-item[aria-disabled] {
    user-select: none;
    position: relative;
}
.doc-types-section .doc-item[aria-disabled]:hover {
    border: 1px solid #ccc;
    cursor: not-allowed;
}
.doc-types-section .doc-item[aria-disabled]::before {
    content: 'coming soon';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .5rem;
    font-size: 12px;
    background-color: rgb(33, 146, 239, .2);
    color: #2192EF;
    padding: .2rem .4rem;
    border-radius: .2rem;
}

.doc-types-section .doc-types-info h2 {
    font-size: 38px;
}
@media (max-width: 450px) {
    .doc-types-section .doc-types-info h2 {
        font-size: 30px;
    }
}
.doc-types-section .doc-types-info ul { margin: 2rem 0; }
.doc-types-section .doc-types-info ul li { list-style-image: url('../assets/images/blue-tick.svg'); }
.doc-types-section .doc-types-info ul li h6 { font-weight: 600; }
.doc-types-section .doc-types-info ul li p { color: #828282; font-weight: 500; }
.doc-types-section .doc-types-info ul li h6,
.doc-types-section .doc-types-info ul li p {
    position: relative;
    left: 10px;
    top: -5px;
}
.doc-types-section .doc-types-info .header-btn { margin-top: 0; }

@media (max-width: 767px) {
    .doc-types-section .inner-section {
        flex-direction: column-reverse;
        gap: 3rem;
    }
    .doc-types-section .doc-types-wrapper {
        max-width: 100%;
    }
}
/* DOC TYPES */

/* BILLING SECTION */
.billing-section {
    background-color: #192A56;
    padding: 4rem 0;
}
.billing-section h2 {
    font-size: 38px;
    color: #fff;
    text-align: center;
    margin-bottom: 2rem;
}
@media (max-width: 450px) {
    .billing-section h2 {
        font-size: 30px;
    }
}
/* BILLING SECTION */

/* CUSTOMERS SECTION */
.customers-section {
    padding: 4rem 0;
}
.customers-section h2 {
    font-size: 38px;
    color: #1A1A1A;
    text-align: center;
    margin-bottom: 2rem;
}
@media (max-width: 450px) {
    .customers-section h2 {
        font-size: 30px;
    }
}

.customers-section .customer-personal-info {
    display: flex;
    align-items: flex-start;
    column-gap: 1rem;
}
.customers-section .customer-personal-info img {
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
}
.customers-section .customer-personal-info h4 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0;
}
.customers-section .customer-personal-info p {
    color: #70757A;
    font-size: 13px;
    line-height: 1.3;
    font-weight: 500;
    margin-bottom: 0;
}

.customers-section .customers-singles-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}
.customers-section .customers-singles-wrapper .customer-single {
    max-width: 340px;
    border: 1px solid #E7E7E7;
    padding: 2rem;
}
.customers-section .customer-single .customer-opinion {
    margin-top: 1rem;
}
.customers-section .customer-single .customer-opinion .rating {
    display: flex;
    align-items: center;
    column-gap: .3rem;
    margin-bottom: .4rem;
}
.customers-section .customer-single .customer-opinion p {
    color: #4F4F4F;
    font-size: 15px;
    font-weight: 400;
}
/* CUSTOMERS SECTION */

/* FOOTER */
footer {
    background-color: #F5F5F5;
    padding: 2rem 0;
}
footer .inner-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
}
footer .inner-section p {
    margin-bottom: 0;
    color: #192A56;
    font-weight: 500;
}
footer .inner-section .payment-methods {
    display: flex;
    column-gap: 1rem;
}
@media (max-width: 767px) {
    footer .inner-section { gap: 1rem }
    footer .inner-section { justify-content: center; }
    footer .inner-section .payment-methods { column-gap: .4rem; }
    footer .inner-section p { font-size: 14px; }
}
/* FOOTER */
</style>
