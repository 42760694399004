import axios from 'axios';

export const api = axios.create({
	baseURL: process.env.VUE_APP_API_URL || "https://x.elegantdoc.com/api0/",
})

api.interceptors.request.use(
	config => {
		const token = localStorage.getItem('accessToken');
		config.headers['Authorization'] = `Bearer ${ token }`
		return config;
	},
	error => Promise.reject(error),
);

