<template>
    <div class="admin-wrapper">
        <h5>Admin Interface</h5>
        <h2>Events</h2>
        <div class="events-wrapper">
            <table>
                <thead>
                    <tr>
                        <th>Level</th>
                        <th>IP</th>
                        <th>Time (YYYY-MM-DD)</th>
                        <th>Country</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(event, i) in events">
                        <td>{{event.level}}</td>
                        <td>{{ event.ip }}</td>
                        <td>{{ unixToHuman(event.created) }}</td>
                        <td>{{ event.country }}</td>
                        <td>{{ event.name }}</td>
                        <td>{{ event.email }}</td>
                        <td><Button class="body-btn" @click.prevent.native="showBody(event, i)">Body</Button></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <adminBodyModal :id="'admin-body'" :event="currentBodyEvent"  />
    </div>
</template>
<script>
import {api} from '@/mixins/axios';
import Button from '@/components/Atoms/Button'
import adminBodyModal from '@/components/Modals/adminBodyModal'
export default {
    data() {
        return {
            events: [],
            currentBodyEvent: {}
        }
    },
    components: { Button, adminBodyModal },
    methods: {
        showBody(e, i) {
            this.currentBodyEvent = this.events[i]
            this.$bvModal.show('admin-body')
        },
        unixToHuman(unixTimestamp) {
            const date = new Date(unixTimestamp * 1000);

            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            const hours = date.getHours();
            const minutes = "0" + date.getMinutes();
            const seconds = "0" + date.getSeconds();

            const formattedTime = `${year}-${month}-${day} // ${hours}:${minutes.substr(-2)}:${seconds.substr(-2)}`;

            return formattedTime;
        }
    },
    async mounted() {
        try {
            let response = await api.post('/event/search', {paginate_by: 100})
            if(response.data.msg == 'ok') {
                this.events = response.data.data
            }
        } catch (e) {
            console.log(e)
        }
    }
}
</script>
<style>
    .admin-wrapper {
        padding: 2rem 0;
        max-width: 1296px;
        margin: 0 auto;
    }
    
    @media (max-width: 1399px) { .admin-wrapper { max-width: calc(1140px - (1.5rem * 0.5)); } }
    @media (max-width: 1199px) { .admin-wrapper { max-width: calc(936px - (1.5rem * 0.5)); } }
    @media (max-width: 991px) { .admin-wrapper { max-width: calc(696px - (1.5rem * 0.5)); } }
    @media (max-width: 767px) { .admin-wrapper { max-width: calc(516px - (1.5rem * 0.5)); } }
    @media (max-width: 530px) { .admin-wrapper { max-width: 90%; } }

    .admin-wrapper h2, .admin-wrapper h5 { font-weight: 600; }
    .admin-wrapper table { width: 100%; margin-top: 2rem; }
    .admin-wrapper table th,
    .admin-wrapper table td {
        padding: 1rem;
        border-bottom: 1px solid rgb(216, 216, 216);
    }
    .admin-wrapper .body-btn {
        padding: .2rem 1rem;
        font-size: 16px !important;
        border-radius: .3rem !important;
    }
</style>