<template>
    <div class="">
		<InputField :id="id" labelBody="Doc Title" keyToEdit="title" inputType="text" :data="doc.title" />
		<AdvancedOptions @click.prevent.native="settings_collapsed = !settings_collapsed" :settings_collapsed="settings_collapsed" />
		<div :class="!settings_collapsed ? 'collapsed not-collapsed' : 'collapsed'">
			<div class="two-fields-one-row">
				<InputField :id="id" labelBody="Address" keyToEdit="txt5" inputType="text" :data="doc.txt5" />
				<InputField :id="id" labelBody="Telephone" keyToEdit="txt6" inputType="text" :data="doc.txt6" />
				<InputField :id="id" labelBody="Website" keyToEdit="txt7" inputType="text" :data="doc.txt7" />
				<InputField :id="id" labelBody="Email" keyToEdit="txt8" inputType="text" :data="doc.txt8" />
				<InputField :id="id" labelBody="Top Text" keyToEdit="txt9" inputType="text" :data="doc.txt9" />
			</div>
			<div v-if="userInfo[0].plan == 'free' || userInfo[0].plan == 'pro'" class="form-check mb-3" style="cursor: pointer" @click="$bvModal.show('modal-upgrade')">
				<input checked disabled class="form-check-input" type="checkbox" id="elegant_doc_reference">
				<label class="form-check-label" style="cursor: pointer" for="elegant_doc_reference">Show ElegantDoc Reference</label>
			</div>
			<label v-else for="elegant_doc_reference" class="form-check mb-3" style="cursor: pointer">
				<input ref="ed_reference_input" class="form-check-input" type="checkbox" v-model="show_ed_reference" id="elegant_doc_reference" @input="show_ed_reference = !show_ed_reference, docUpdate({show_ed_reference: JSON.stringify(show_ed_reference)}, id)">
				<span class="form-check-label" style="cursor: pointer; user-select: none;" for="elegant_doc_reference">Show ElegantDoc Reference</span>
			</label>
			<FileUploader :id="id" labelBody="Logo" keyToEdit='file1' inputType="file" inputID="file-input" :isFile="doc.file1" />
			<div class="mb-3" @click="show_designs_modal()">
				<label style="display: block;">
					<div class="form-file change-design-wrapper">
						<span>Change Design</span>
						<Button class="file-upload-button">Choose</Button>
					</div>
				</label>
			</div>
            <div class="two-fields-one-row color-picker">
                <ColorPicker :id="id" name="Primary Text Color" :data="color1" obj_key="color1" />
				<ColorPicker :id="id" name="Primary Background Color" :data="color2" obj_key="color2" />
				<ColorPicker :id="id" name="Banner Text Color" :data="color3" obj_key="color3" />
				<ColorPicker :id="id" name="Banner Background Color" :data="color4" obj_key="color4" />
				
				<SelectField :id="id" labelBody="H1 Font Size" keyToEdit="size1" :data="doc.size1" :itemsToLoopThrough="fontSizes" />
				<SelectField :id="id" labelBody="H2 Font Size" keyToEdit="size2" :data="doc.size2" :itemsToLoopThrough="fontSizes" />
				<SelectField :id="id" labelBody="H3 Font Size" keyToEdit="size3" :data="doc.size3" :itemsToLoopThrough="fontSizes" />
				<SelectField :id="id" labelBody="Text Font Size" keyToEdit="size4" :data="doc.size4" :itemsToLoopThrough="fontSizes" />
			</div>
        </div>
        <Markdown :id="id" markdown_num="markdown1" :data="doc.markdown1" labelBody="Body" />

        <Flash ref="show"/>
		<ModalUpgradePlan :id="'modal-upgrade'"/>
		<DesignsModal :id="'designs-modal'" :template_id="id" :designs_src="memo_designs" design_key="txt1" />
    </div>
</template>

<script> 
	import {eventBus} from '@/main'
	// Modals
	import DesignsModal from "@/components/Modals/DesignsModal.vue";
	import ModalUpgradePlan from "@/components/Modals/ModalUpgradePlan";
	// Atoms
	import Flash from "@/components/Atoms/Flash";
	import ColorPicker from "@/components/Atoms/ColorPicker";
	import Button from "@/components/Atoms/Button";
	import InputField from "@/components/Atoms/InputField";
	import SelectField from "@/components/Atoms/SelectField";
	import FileUploader from "@/components/Atoms/FileUploader";
	import AdvancedOptions from "@/components/Atoms/AdvancedOptions";
	// Organisms
	import Markdown from "@/components/Organisms/Markdown";
	// Mixins
	import editor from '@/mixins/editor';
	import apiCalls from '@/mixins/apiCalls'

	export default {
		name: 'Report',
		mixins:[editor, apiCalls],
		props:{
			doc:{ type: Object },
			id:{ type: String },
			userInfo: { type: Array }
		},
		data() {
			return {
				settings_collapsed: true,

				color1: {hex: this.doc.color1 || "#21405b"},
				color2: {hex: this.doc.color2 || "#ffffff"},
				color3: {hex: this.doc.color3 || "#ff7a00"},
				color4: {hex: this.doc.color4 || "#5b3eb7"},

				memo_designs: [
                    {
                        src: require('@/assets/images/memo-design-1.png'),
                        design_name: 'design_1'
                    }, {
                        src: require('@/assets/images/memo-design-2.png'),
                        design_name: 'design_2'
                    }
                ],

				fontSizes: [
					{ title: '18px', value: 18 },
					{ title: '20px', value: 20 },
					{ title: '24px', value: 24 },
					{ title: '32px', value: 32 }
				],
				show_ed_reference: JSON.parse(this.doc.show_ed_reference)
			}
		},
		components:{
			Flash,
			ColorPicker,
			Markdown,
			Button,
			DesignsModal,
			InputField,
			SelectField,
			FileUploader,
			AdvancedOptions,
			ModalUpgradePlan
		},
		methods: {
			checkFontSizeValue(key, font_size) {
				if (font_size <= 13) {
					if (key == 'size1') {
						this.doc.size1 = 13
					} else if (key == 'size2') {
						this.doc.size2 = 13
					} else {
						this.doc.size3 = 13
					}

					font_size = 13
					this.docLazyUpdate({[key]: `${font_size}`}, this.id)
				} else {
					this.docLazyUpdate({[key]: `${font_size}`}, this.id)
				}
			},
			show_designs_modal() {
				this.$bvModal.show('designs-modal')
				setTimeout(() => {
					document.querySelector('.VueCarousel-inner').style.visibility = 'visible'
					document.querySelector('.VueCarousel-inner').style.flexBasis = '333px'
				}, 50)
			}
		},
		mounted() {
			eventBus.$on('saveEditorChanges', (value) => {
				this.doc.body = value;
			});
		}
	}
</script>

<style scoped lang="scss">

	.file-upload-button {
		font-size: 14px !important;
		border-radius: 4px !important;
		padding: .4rem .6rem;
		background-color: #2192EF !important;
		font-weight: 600 !important;
		pointer-events: none;
		margin-left: auto;
	}

	.collapsed {
		padding: 0;
		height: 0;
		overflow-y: hidden;
		outline: none;
		transition: 200ms ease-in-out height,
					200ms ease-in-out padding,
					200ms ease-in-out box-shadow;
	}
	.not-collapsed {
		height: calc(905px + .75rem);
		overflow: inherit;
	}
	@media (max-width: 1141px) {
		.not-collapsed { height: calc(920px + .75rem); }
	}
	@media (max-width: 1063px) {
		.not-collapsed { height: calc(940px + .75rem); }
	}

	.two-fields-one-row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 2rem;
	}
</style>
