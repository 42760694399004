<template>
    <div class="form-group mb-3">
        <label class="form-label">{{labelBody}}</label>
        <div class="markup-editor">
            <div class="editor-nav-container">
                <div class="editor-nav">
                    <a href="#" @click.prevent="newH1(id)" class="btn btn-sm">H1</a>
                    <a href="#" @click.prevent="newH2(id)" class="btn btn-sm">H2</a>
                    <a href="#" @click.prevent="newH3(id)" class="btn btn-sm">H3</a>
                    <a href="#" @click.prevent="newItalic(id)" class="btn btn-sm"
                        ><i>I</i></a
                    >
                    <a href="#" @click.prevent="newBold(id)" class="btn btn-sm"
                        ><b>B</b></a
                    >
                    <a href="" @click.prevent="$bvModal.show('image-modal')" class="btn btn-sm">IMG</a>
                    <a href="#" @click.prevent="newListUl(id)" class="btn btn-sm">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                                d="M16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144C37.49 144 16 122.5 16 96zM480 64C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H192C174.3 128 160 113.7 160 96C160 78.33 174.3 64 192 64H480zM480 224C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H192C174.3 288 160 273.7 160 256C160 238.3 174.3 224 192 224H480zM480 384C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H192C174.3 448 160 433.7 160 416C160 398.3 174.3 384 192 384H480zM16 416C16 389.5 37.49 368 64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416zM112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208C90.51 208 112 229.5 112 256z"
                            />
                        </svg>
                    </a>
                    <a href="#" @click.prevent="newListOl(id)" class="btn btn-sm">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                        >
                            <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                                d="M55.1 56.04C55.1 42.78 66.74 32.04 79.1 32.04H111.1C125.3 32.04 135.1 42.78 135.1 56.04V176H151.1C165.3 176 175.1 186.8 175.1 200C175.1 213.3 165.3 224 151.1 224H71.1C58.74 224 47.1 213.3 47.1 200C47.1 186.8 58.74 176 71.1 176H87.1V80.04H79.1C66.74 80.04 55.1 69.29 55.1 56.04V56.04zM118.7 341.2C112.1 333.8 100.4 334.3 94.65 342.4L83.53 357.9C75.83 368.7 60.84 371.2 50.05 363.5C39.26 355.8 36.77 340.8 44.47 330.1L55.59 314.5C79.33 281.2 127.9 278.8 154.8 309.6C176.1 333.1 175.6 370.5 153.7 394.3L118.8 432H152C165.3 432 176 442.7 176 456C176 469.3 165.3 480 152 480H64C54.47 480 45.84 474.4 42.02 465.6C38.19 456.9 39.9 446.7 46.36 439.7L118.4 361.7C123.7 355.9 123.8 347.1 118.7 341.2L118.7 341.2zM512 64C529.7 64 544 78.33 544 96C544 113.7 529.7 128 512 128H256C238.3 128 224 113.7 224 96C224 78.33 238.3 64 256 64H512zM512 224C529.7 224 544 238.3 544 256C544 273.7 529.7 288 512 288H256C238.3 288 224 273.7 224 256C224 238.3 238.3 224 256 224H512zM512 384C529.7 384 544 398.3 544 416C544 433.7 529.7 448 512 448H256C238.3 448 224 433.7 224 416C224 398.3 238.3 384 256 384H512z"
                            />
                        </svg>
                    </a>
                    <a
                        href="#"
                        @click.prevent="newLine(id)"
                        class="btn btn-sm btn-new-line"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                        >
                            <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
                            <path
                                d="M313.553 392.331L209.587 504.334c-9.485 10.214-25.676 10.229-35.174 0L70.438 392.331C56.232 377.031 67.062 352 88.025 352H152V80H68.024a11.996 11.996 0 0 1-8.485-3.515l-56-56C-4.021 12.926 1.333 0 12.024 0H208c13.255 0 24 10.745 24 24v328h63.966c20.878 0 31.851 24.969 17.587 40.331z"
                            />
                        </svg>
                    </a>
                </div>
                <p @click="$bvModal.show('modal-instructions')">What is markdown?</p>
            </div>
            <textarea
                :id="`editorArea-1`"
                class="form-control"
                v-model="data_mutation"
                required
                ref="textarea1"
                @input="docLazyUpdate({ [markdown_num]: data_mutation }, id)"
            ></textarea>
            <span class="focus-border"></span>
            <MarkdownInstructions :id="'modal-instructions'"/>
            <ImageModal :id="'image-modal'" />
        </div>
        <Flash ref="show"/>
    </div>
</template>

<script>
import { eventBus } from "@/main";
import editor from "@/mixins/editor";
import apiCalls from "@/mixins/apiCalls";
import MarkdownInstructions from "@/components/Modals/MarkdownInstructions";
import ImageModal from "@/components/Modals/ImageModal";
import Flash from "@/components/Atoms/Flash";

export default {
    name: "Markdown",
    mixins: [editor, apiCalls],
    components: {
        MarkdownInstructions,
		Flash,
        ImageModal
    },
    props: {
        id: {
            type: String,
        },
        markdown_num: {
            type: String
        },
        data: {
            type: String
        },
        labelBody: {
            type: String
        }
    },
    data() {
        return {
            data_mutation: this.data
        }
    }, 
    methods: {
        image_uploadd (url) {
            this.newImg(url, this.id)
        },
        showFlashError(msg) {
            this.$refs.show.showFlashError(msg)
        }
    },
    mounted() {
        eventBus.$on('image_upload', this.image_uploadd)
        eventBus.$on('showEditorFlash', this.showFlashError)

        if (document.querySelector('.editor-form').clientHeight > 564) {
            document.querySelector('.editor-form textarea').style.height = `${document.querySelector('.editor-form').clientHeight - document.querySelector('.editor-form > div').clientHeight + 150}px`
        }
    },
    destroyed() {
        eventBus.$off('showEditorFlash', this.showFlashError)
    }
};
</script>

<style>
.form-label {
    font-size: 14px;
	font-weight: 500;
    margin-bottom: 6px !important;
}
</style>
