<template>
    <div class="pricing-plans">
        <div class="pricing-item" :style="currentPlan == 'free' ? 'border: 3px solid #009E74; box-shadow: 0px 0px 20px 2px rgba(0, 158, 116, 0.45);' : ''">
            <div class="top-part-card">
                <div class="head">
                    <h6 style="color: #009E74;">Free</h6>
                    <h2>$0</h2>
                </div>
                <hr>
                <div class="plan-features">
                    <ul>
                        <li>
                            <img src="@/assets/images/green-tick.svg" alt="">
                            <span>500 Free Documents</span>
                        </li>
                        <li>
                            <img src="@/assets/images/green-tick.svg" alt="">
                            <span>100MB Storage Space</span>
                        </li>
                        <li>
                            <img src="@/assets/images/green-tick.svg" alt="">
                            <span>5 templates </span>
                        </li>
                    </ul>
                </div>
            </div>
            <a v-if="currentPlan == 'free'" class="card-btn btn-transparent current-plan">Current Plan</a>
            <a v-else href="#" class="card-btn btn-transparent" @click.prevent="buyingPackage('free')">Choose plan</a>
        </div>
        <div class="pricing-item" :style="currentPlan == 'pro' ? 'border: 3px solid #2192EF; box-shadow: 0px 0px 20px 2px rgba(33, 146, 239, 0.45);' : ''">
            <div class="top-part-card">
                <div class="head">
                    <h6 style="color: #2192EF; display: flex; align-items: flex-start; column-gap: .5rem; justify-content: center;"><img src="@/assets/images/pro-price-logo.svg"><span>PRO</span></h6>
                    <h2>$9.99</h2>
                </div>
                <hr>
                <div class="plan-features">
                    <ul>
                        <li>
                            <img src="@/assets/images/blue-tick.svg" alt="">
                            <span>1000 Documents</span>
                        </li>
                        <li>
                            <img src="@/assets/images/blue-tick.svg" alt="">
                            <span>1GB Storage Space</span>
                        </li>
                        <li>
                            <img src="@/assets/images/blue-tick.svg" alt="">
                            <span>5 Templates </span>
                        </li>
                        <li>
                            <img src="@/assets/images/blue-tick.svg" alt="">
                            <span>More Customization </span>
                        </li>
                    </ul>
                </div>
            </div>
            <a v-if="currentPlan == 'pro'" class="card-btn btn-transparent current-plan">Current Plan</a>
            <a v-else href="#" class="card-btn" @click.prevent="buyingPackage('pro')">Choose plan</a>
        </div>
        <div class="pricing-item" :style="currentPlan == 'business' ? 'border: 3px solid #ECB831; box-shadow: 0px 0px 20px 2px rgba(236, 184, 49, 0.45);' : ''">
            <div class="top-part-card">
                <div class="head">
                    <h6 style="color: #ECB831">Business</h6>
                    <h2>$39.99</h2>
                </div>
                <hr>
                <div class="plan-features">
                    <ul>
                        <li>
                            <img src="@/assets/images/orange-tick.svg" alt="">
                            <span>10000 Documents</span>
                        </li>
                        <li>
                            <img src="@/assets/images/orange-tick.svg" alt="">
                            <span>10GB Storage Space</span>
                        </li>
                        <li>
                            <img src="@/assets/images/orange-tick.svg" alt="">
                            <span>5 Templates </span>
                        </li>
                        <li>
                            <img src="@/assets/images/orange-tick.svg" alt="">
                            <span>Customize Everything</span>
                        </li>
                        <li>
                            <img src="@/assets/images/orange-tick.svg" alt="">
                            <span>Remove ElegantDoc.com Reference</span>
                        </li>
                    </ul>
                </div>
            </div>
            <a v-if="currentPlan == 'business'" class="card-btn btn-transparent current-plan">Current Plan</a>
            <a v-else class="card-btn btn-transparent" @click.prevent="buyingPackage('business')">Choose plan</a>
        </div>
        <div class="pricing-item" :style="currentPlan == 'corporate' ? 'border: 3px solid #6D6DBB;  box-shadow: 0px 0px 20px 2px rgba(109, 109, 187, 0.45);' : ''">
            <div class="top-part-card">
                <div class="head">
                    <h6 style="color: #6D6DBB">Corporate</h6>
                    <h2 style="color: #fff;user-select: none;pointer-events: none;">.</h2>
                </div>
                <hr>
                <div class="plan-features">
                    <ul>
                        <li>
                            <img src="@/assets/images/purple-tick.svg" alt="">
                            <span>Unlimited Documents</span>
                        </li>
                        <li>
                            <img src="@/assets/images/purple-tick.svg" alt="">
                            <span class="extra-feature">100GB Storage Space</span>
                        </li>
                        <li>
                            <img src="@/assets/images/purple-tick.svg" alt="">
                            <span>5 templates</span>
                        </li>
                        <li>
                            <img src="@/assets/images/purple-tick.svg" alt="">
                            <span>Custom Design Templates</span>
                        </li>
                        <li>
                            <img src="@/assets/images/purple-tick.svg" alt="">
                            <span>Remove ElegantDoc.com Reference</span>
                        </li>
                    </ul>
                </div>
            </div>
            <a v-if="currentPlan == 'corporate'" class="card-btn btn-transparent current-plan">Current Plan</a>
            <a v-else class="card-btn btn-transparent" @click.prevent="contactSales">Contact Sales</a>
        </div>
        <div style="position: absolute;">
            <thankYouModal :id="'thank-you'" />
            <Flash ref="show" />
            <CorporatePlan :id="'corporate'" />
            <FreePlanModal :id="'modal-confirm'" :userInfo="userInfo" />
        </div>
    </div>
</template>
<script>
import Button from '@/components/Atoms/Button'
import thankYouModal from '@/components/Modals/thankYouModal'
import CorporatePlan from '@/components/Modals/CorporatePlan'
import FreePlanModal from '@/components/Modals/FreePlanModal'
import Flash from '@/components/Atoms/Flash'
import apiCalls from '@/mixins/apiCalls';

export default {
    mixins:[apiCalls],
    data() {
        return {
            userID: null,
            currentPlan: '',
            userInfo: []
        }
    },
    components: { Button, thankYouModal, Flash, CorporatePlan, FreePlanModal },
    methods: {
        buyingPackage(plan) {
            if (localStorage.accessToken && this.userID){
                if (plan == 'free') this.$bvModal.show('modal-confirm')
                else if (plan == 'pro' && window.location.host !== 'elegantdoc.com') window.location.href = `https://buy.stripe.com/test_aEU16202J9q62gofZ3?client_reference_id=${this.userID}`
                else if (plan == 'business' && window.location.host !== 'elegantdoc.com') window.location.href = `https://buy.stripe.com/test_28o4iecPv0TA4ow7sw?client_reference_id=${this.userID}`
                else if (plan == 'pro' && window.location.host === 'elegantdoc.com') window.location.href = `https://buy.stripe.com/4gweY03Rf1iBeJOfZ3?client_reference_id=${this.userID}`
                else if (plan == 'business' && window.location.host === 'elegantdoc.com') window.location.href = `https://buy.stripe.com/fZe17a2Nb5yRbxC3ci?client_reference_id=${this.userID}`
            } else {
                this.$router.push('/signup')
            }
        },
        contactSales() {
            if (localStorage.accessToken) {
                this.$bvModal.show('corporate')
            } else {
                this.$router.push('/signup')
            }
        }
    },
    async mounted() {
        if (localStorage.accessToken && localStorage.EDuserID) {
            this.userID = localStorage.getItem('EDuserID')
            this.userInfo = await this.getUser(this.userID)
            this.currentPlan = this.userInfo[0].plan
        }
    }
}
</script>
<style lang="scss">
.pricing-plans {
    display: flex;
    justify-content: center;
    gap:24px;
    flex-wrap: wrap;
}

.pricing-item {
    box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 300px;
    min-width: 250px;
    width: 100%;
    min-height: 470px;
    background-color: #fff;

    .head{
        padding: 26px;
        padding-bottom: .7rem;
        text-align: center;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        position: relative;
        overflow: hidden;

        h6 { font-weight: 600; }
        h2 { color: #192A56; font-weight: 700; margin-bottom: 0; }
    }

    .plan-features {
        padding: .5rem 26px;
        ul {
            padding-left: 0;
            li {
                color: #4F4F4F;
                font-size: 15px;
                font-weight: 500;
                list-style-type: none;
                display: flex;
                align-items: flex-start;
                gap: .5rem;

                &:not(:first-of-type) {
                    margin-top: 1rem;
                }

                span {
                    display: inline-block;
                    transform: translateY(-4px)
                }
                .extra-feature {
                    margin-bottom: 1rem;
                    position: relative;
                }
                .extra-feature::before {
                    content: '+ $.5 Per extra';
                    color: #046FC8;
                    position: absolute;
                    bottom: -100%;
                    left: 0;
                    font-size: 13px;
                }
            }
        }
    }

    .card-btn {
        width: 100%;
        max-width: calc(100% - 52px);
        margin: 0 auto;
        margin-bottom: 26px;
        border-radius: .4rem !important;
        font-size: 16px !important;
        font-weight: 600;
        padding: 10px;
        text-align: center;
        letter-spacing: .02em;
        text-transform: capitalize;
        border: 0;
        background: #046FC8;
        color: #fff;
        outline: none;
        text-decoration: none;
        cursor: pointer;
    }
    .card-btn:hover {
        color: #fff;
        background: #2192ef;
    }
    .card-btn.btn-transparent {
        background-color: transparent;
        border: 2px solid #E0E0E0;
        color: #000;
    }
    .card-btn.current-plan {
        background-color: transparent;
        border: none;
        cursor: alias;
        color: #bfbfbf;
    }
}
</style>