<template>
	<div class="form-group mb-3">
		<label class="form-label">{{ labelBody }}</label>
		<select class="form-select" @change="docUpdate({[keyToEdit]: data_mutation}, id)" v-model="data_mutation">
			<option v-for="(item, index) in itemsToLoopThrough" :key="index" :value="item.value">
				{{item.title}}
			</option>
		</select>
		<Flash ref="show" />
	</div>
</template>

<script>
import apiCalls from "@/mixins/apiCalls";
import Flash from '@/components/Atoms/Flash'

export default {
    name: 'SelectField',
    props: {
        id: { type: String },
        labelBody: { type: String },
        keyToEdit: { type: String },
        data: { type: String },
		itemsToLoopThrough: { type: Array }
    },
    data () {
        return {
            data_mutation: this.data
        }
    },
    mixins: [apiCalls],
	components: { Flash }
}
</script>

<style scoped lang="scss">
    .form-group {
		position: relative;
	}
    .form-select {
		outline: none;
		box-shadow: unset;
		padding: 12px 16px;
		color: #1E2532;
		height: 54px;
		border-radius: 4px;

		background-color: rgb(239, 242, 249);
		border: 0;

		&:focus {
			outline: none;
			box-shadow: unset;
		}
	}

	.form-label {
		color: rgb(130, 139, 162);
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		margin-bottom: 6px;
		width: 100%;
	}
</style>