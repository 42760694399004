<template>
	<div class="mb-3">
		<label :for="inputID" class="form-label">
			<span>{{labelBody}}</span>
			<div class="form-file" style="display: flex;align-items:center;justify-content:space-between">

				<input style="display: none" @change="uploading_file($event, keyToEdit, id, isFile_mutation)" :type="inputType" :id="inputID">
				<div style="display: flex; align-items:center">
					<img src="@/assets/images/image-upload-logo.svg" alt="">
					<span style="margin-left: .5rem;">{{imageTitle}}</span>
				</div>
				<Button class="file-upload-button">Choose</Button>
			</div>
		</label>
		<p :style="isFile_mutation ? 'display: block' : 'display: none'" class="remove-img" @click="docUpdate({[keyToEdit]: ''}, id, $event, isFile_mutation)">Remove this image</p>
		<Flash ref="show" />
	</div>
</template>

<script>
import apiCalls from "@/mixins/apiCalls";
import Button from '@/components/Atoms/Button'
import Flash from '@/components/Atoms/Flash'

export default {
    name: 'FileUploader',
    props: {
        id: { type: String },
        labelBody: { type: String },
        keyToEdit: { type: String },
        inputType: { type: String },
		inputID: { type: String },
		isFile: { type: String }
    },
	components: {Button, Flash},
    data () {
        return {
            data_mutation: this.data,
			isFile_mutation: this.isFile !== '' ? true : false,
			imageTitle: this.isFile ? 'An Image has already been added' : 'No file selected'
        }
    },
    mixins: [apiCalls]
}
</script>

<style scoped lang="scss">
	.form-label {
		color: rgb(130, 139, 162);
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		margin-bottom: 6px;
		width: 100%;
	}
	.remove-img {
		color: #046FC8;
		font-size: 14px;
		cursor: pointer;
	}
	.remove-img:hover {
		color: #5b3eb7;
	}
	.file-upload-button {
		font-size: 14px !important;
		border-radius: 4px !important;
		padding: .4rem .6rem;
		background-color: #2192EF !important;
		font-weight: 600 !important;
		pointer-events: none;
	}
</style>