import {eventBus} from '@/main'
import {api} from "@/mixins/axios";

export default {
    methods: {
        docLazyUpdate(data, id) {
            eventBus.$emit('savingData');
            clearTimeout(this.cacheTimer)
            this.cacheTimer = setTimeout(() => {
                this.docUpdate(data, id)
            }, 2000)
        },
        async docUpdate(data, id, el) {
            try {
                data.id = id
                let response = await api.post("/doc/update", data);

                if (response.data.msg == 'ok') {
                    eventBus.$emit('contextUpdated');
                    if (el) {
                        el.target.style.display = 'none'
                        el.target.previousElementSibling.querySelector('.form-file span').innerText = 'No file selected'
                        el.target.previousElementSibling.querySelector('.form-file span').style.color = 'rgb(130, 139, 162)'
                        el.target.previousElementSibling.querySelector('.form-file .file-upload-button').setAttribute('style', "background-color: #2192EF") 
                    }
                }

            } catch (e) {
                this.$refs.show.showFlashError('Something went wrong.')
            }
        },
        updateLine(data) {
            eventBus.$emit('savingData');
            clearTimeout(this.cacheTimer)
            this.cacheTimer = setTimeout(async () => {
                try {
                    let response = await api.post("/docline/update", data);

                    if (response.data.msg == 'ok') {
                        eventBus.$emit('contextUpdated');
                    }

                } catch (e) {
                    this.$refs.show.showFlashError('Something went wrong.')
                }
            }, 2000)
        },
        async addItem(lines, id) {
            let data = {
                doc: id,
                txt1: JSON.stringify(lines.length + 1),
                txt2: 100,
                txt3: 2,
                txt4: 'Content Writing'
            }
            let docline_response = await api.post('/docline/create', data)
            if (docline_response.data.msg == 'ok') {
                lines.push(docline_response.data.data)
                eventBus.$emit('contextUpdated');
            }
        },
        async removeItem(lines, data) {
            if (lines.length > 1) {
                let docline_response = await api.post('/docline/delete', data)
                if (docline_response.data.msg == 'ok') {
                    let findObjInArr = lines.findIndex(item => item.id == data.id)
                    lines.splice(findObjInArr, 1)
                    eventBus.$emit('contextUpdated');
                }
            }
        },
        async switchWithTop(lines, data){
            let findClickItemIndex = lines.findIndex(item => item.id == data.id)
            try {
                let response = await api.post('/docline/swap', {id1: data.id, id2: lines[findClickItemIndex - 1].id})
                if(response.data.msg == 'ok') {
                    lines.splice(findClickItemIndex, 1, lines.splice(findClickItemIndex - 1, 1, lines[findClickItemIndex])[0]);
                    eventBus.$emit('contextUpdated');
                }
            } catch(e) {
                this.$refs.show.showFlashError('No item to swap with.')
            }
        },
        async switchWithBottom(lines, data) {
            let findClickItemIndex = lines.findIndex(item => item.id == data.id)
            try {
                let response = await api.post('/docline/swap', {id1: data.id, id2: lines[findClickItemIndex + 1].id})
                if(response.data.msg == 'ok') {
                    lines.splice(findClickItemIndex, 1, lines.splice(findClickItemIndex + 1, 1, lines[findClickItemIndex])[0]);
                    eventBus.$emit('contextUpdated');
                }
            } catch(e) {
                this.$refs.show.showFlashError('No item to swap with.')
            }
        },
        async uploading_file(e, file_key, id) {
            let formData = new FormData()
            const fileSizeInBytes = e.target?.files[0].size
            const fileSizeInMegaBytes = (fileSizeInBytes / (1024 * 1024)).toFixed(2)
            if(Number(fileSizeInMegaBytes) < 10) {
                formData.append('file', e.target?.files[0])
                try {
                    let response = await api.post("/files/upload", formData);
                    
                    if (response.data.msg == 'ok') {
                        this.docUpdate({[file_key]: response.data.url}, id)
                        e.target.parentElement.parentElement.nextElementSibling.style.display = 'block'
                        e.target.nextElementSibling.querySelector('span').innerText = e.target.files[0].name
                        e.target.nextElementSibling.querySelector('span').style.color = '#000'
                        e.target.nextElementSibling.nextElementSibling.setAttribute('style', "background-color: #CDCED3 !important")
                    }
                } catch (e) {
                    this.$refs.show.showFlashError('Something went wrong')
                }
            } else {
                this.$refs.show.showFlashError('File needs to be less than 10MB')
            }
        },
        async downloadDoc(route, title){
            try {
                let link = document.createElement('a');
                let response = await api.get(`/doc/render/${route}.pdf`, {responseType: "blob"});
                let data = window.URL.createObjectURL(new Blob([response.data]));
                
                link.href = data;
                link.download = `${title ? title : "file"}.pdf`;
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                this.$refs.show.showFlashError('Something went wrong.')
            }
        },
        async newDoc(template, doc_default_values, duplicated) {
            if(localStorage.accessToken) {
                try {
                    let response = await api.post('/doc/create', {template: template, ...doc_default_values});
                    
                    if (template == 'invoice' && duplicated) {
                        for (let i = 0; i <= doc_default_values.lines.length - 1; i++) {
                            let docline_response = await api.post('/docline/create', {
                                doc: response.data.data.id,
                                txt2: doc_default_values.lines[i].txt2,
                                txt3: doc_default_values.lines[i].txt3,
                                txt4: doc_default_values.lines[i].txt4,
                                order_id: doc_default_values.lines[i].order_id
                            })
                            if (response.data.msg == 'ok' && i === doc_default_values.lines.length - 1) {
                                this.$router.push(`/editor/${response.data.data.id}`)
                            }	
                        }
                    } else {
                        if (template == 'invoice' && !duplicated) {
                            let lines = [
                                {
                                    desc: 'Web build and design',
                                    qty: 1,
                                    price: 364
                                }, {
                                    desc: 'Adding extra front-end to clients application',
                                    qty: 1,
                                    price: 245
                                }, {
                                    desc: 'Adding back-end to clients applicatio',
                                    qty: 1,
                                    price: 299
                                }
                            ]
                            for(let i = 0; i <= lines.length - 1; i++) {
                                let docline_response = await api.post('/docline/create', {
                                    doc: response.data.data.id,
                                    txt2: lines[i].qty,
                                    txt3: lines[i].price,
                                    txt4: lines[i].desc
                                })
                            }
                        }
                        if (response.data.msg == 'ok') {
                            this.$router.push(`/editor/${response.data.data.id}`)
                        }
                    }
                } catch (e) {
                    this.$refs.show.showFlashError('Something went wrong.')
                }
            } else {
                this.$router.push(`/signup`)
            }
        },
        duplicateTemplate(template, template_fields) {
            delete template_fields.id
            delete template_fields.created
            delete template_fields.updated

            if(template == 'invoice') template_fields.txt4 = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000 )).toISOString().split('T')[0];

            template_fields.is_template = false
            template_fields.title = template_fields.title + ' Copy'
            
            this.newDoc(template, template_fields, true)
        },
        searchDoc(e) {
            this.loading = true
            clearTimeout(this.cacheTimer)
            this.cacheTimer = setTimeout(async () => {
                let response = await api.post('/doc/search', {page: 1, paginate_by: 20, q: e.target.value});
                this.docs = response.data.data
                this.loading = false
            }, 500)
        },
        async getUser(userID) {
            try {
                let response = await api.post('/user/search', {id: userID});
                if (response.data.msg == 'ok') {
                    return response.data.data
                }
            } catch (e) {
                console.log(e);
            }
        }
    }
}