<template>
	<div class="editor">
		<div class="coming-soon d-xl-none">
			Mobile version coming soon!
		</div>
		<div class="custom-container">
			<div class="editor-form">
				<div v-if="loading" class="d-flex justify-content-center align-items-center">
					<b-spinner class="spinner-big"/>
				</div>
				<div v-else style="position: relative">
					<div class="go-to-docs">
						<router-link to="/docs">
							<font-awesome-icon icon="fa-solid fa-arrow-left"/>
							<span>Back to docs</span>
						</router-link>
					</div>
					<div v-if="doc.template == 'report'" class="report">
						<Report :userInfo="userInfo" :doc="doc" :id="id" :designs="designs" />
					</div>
					<div v-else-if="doc.template == 'flyer'" class="flyer">Flyer</div>
					<div v-else-if="doc.template == 'cert'" class="certificate">
						<Certificate :userInfo="userInfo" :doc="doc" :id="id"/>
					</div>
					<div v-else-if="doc.template == 'memo'" class="memo">
						<Memo :userInfo="userInfo" :doc="doc" :id="id"/>
					</div>
					<div v-else-if="doc.template == 'invoice'" class="invoice">
						<Invoice :userInfo="userInfo" :doc="doc" :id="id" :designs="designs"/>
					</div>
				</div>
			</div>
			<div class="preview">

				<div class="page">
					<span v-if="savingData || savedData" class="btn-general loader-animation">
						<span v-if="savingData">
							<b-spinner small />
							Autosaving
						</span>
						<span v-if="savedData">
							<font-awesome-icon icon="fa-solid fa-check" />
							Saved
						</span>
					</span>
					<Button @click.prevent.native="downloadDoc($route.params.id, doc.title)" class="download-pdf">
						Download PDF
					</Button>

					<div class="position-relative" @click.prevent="previewTooltip">
						<div v-if="pdf_page_is_loading" class="d-flex justify-content-center align-items-center pdf_page_loader">
							<b-spinner class="spinner-big"/>
						</div>
						<pdf :key="key"
							:page="pageNum ? Number(pageNum) : 1"
							:fit-to-page="true"
							:src="doc_path"
							@page-loaded="pdf_page_loading_func()"
							@num-pages="pdf_num_pages($event)"
							@progress="progress($event)"
							class="pdf-viewer"
						>
						</pdf>
						<p v-if="showTooltip" class="preview-tooltip">You can change this on the left side</p>
					</div>

					<div class="pdf-pages-tab">
						<Button @click.prevent.native="prevPDFPage" btn_name="<" />
						<div class="form-group">
							<input @input="changePageNum()" class="form-control change-page-num" type="text" v-model="pageNum" />
							<span class="focus-border"></span>
						</div>
						<span> / </span>
						<input class="form-control" type="text" v-model="pagesNum" disabled />
						<Button @click.prevent.native="nextPDFPage" btn_name=">" />

						<Button @click.native="changeCanvasResolution" class="zoom-in-out-btn">
							<font-awesome-icon :icon="isCanvasSmall ? 'fa-solid fa-magnifying-glass-plus' : 'fa-solid fa-magnifying-glass-minus'" />
						</Button>
					</div>
				</div>
			</div>
		</div>

		<Flash ref="show"/>
	</div>
</template>

<script>

	import {eventBus} from '@/main'
	// Atoms
	import Flash from "@/components/Atoms/Flash";
	import Button from "@/components/Atoms/Button";
	// Organisms
	import Report from "@/components/Organisms/Report";
	import Certificate from "@/components/Organisms/Certificate";
	import Invoice from "@/components/Organisms/Invoice";
	import Memo from "@/components/Organisms/Memo";
	// Mixins
	import {api} from "@/mixins/axios";
	import apiCalls from "@/mixins/apiCalls";
	// PDFjs
	import pdf from 'vue-pdf'

	export default {
		name: 'Editor',
		data() {
			return {
				docs: [],
				doc: {},
				id: "",
				loading: true,
				key: 0,
				designs:[],
				template: null,

				pageNum: 1,
				pagesNum: 0,
				pdf_page_is_loading: true,

				isCanvasSmall: true,

				savingData: false,
				savedData: false,

				doc_path: null,

				userInfo: [],
				showTooltip: false
			}
		},
		components: {
			Flash,
			Report,
			Certificate,
			Invoice,
			Memo,
			Button,
			pdf
		},
		mixins: [apiCalls],
		methods: {
			pdf_page_loading_func() {
				this.pdf_page_is_loading = false
			},
			prevPDFPage() {
				if (this.pageNum <= 1) return
				this.pageNum = this.pageNum - 1
			},
			nextPDFPage() {
				if(this.pagesNum === undefined) return
				else {
					if (this.pageNum >= this.pagesNum) return
					this.pageNum = this.pageNum + 1
				}
			},
			changePageNum() {
				var inputValue = this.pageNum;
				var cleanedValue = inputValue.replace(/[^\d]/g,'');
				var currentValue = this.pageNum;
				if(currentValue !== cleanedValue) {
					this.pageNum = cleanedValue;
				} else {
					setTimeout(() => {
						if (this.pageNum > this.pagesNum) {
							this.pageNum = this.pagesNum
						} else if (this.pageNum < 1) {
							this.pageNum = 1
						}
					}, 1500)
				}
				this.checkCanvasResolution()
			},
			pdf_num_pages(e) {
				this.pagesNum = e
				if (this.pageNum > this.pagesNum) {
					this.pageNum = this.pagesNum
				}
			},
			progress(e) {
				if (e < 1) {
					this.savingData = true
				} else {
					this.savingData = false
					this.savedData = true
					document.querySelectorAll('.item-btn').forEach(btn => {
						btn.classList.remove('item-btn-disabled')
						btn.removeAttribute('disabled')
					})
					this.checkCanvasResolution()
					setTimeout(() => {
						this.savedData = false
					}, 2000)
				}
			},
			async getDocs() {
				if (!localStorage.accessToken) return

				this.loading = true

				try {
					let response = await api.post('/doc/search', {});
					this.loading = false
					
					if(response.data.data) this.docs = response.data.data
					let docInfo = this.docs.filter(item => item.id == this.id)[0]
					
					this.doc = docInfo
					await this.render_doc()

					if(this.doc.title){
						document.title = `${this.doc.title} | ElegantDoc.com`
					} else document.title = "ElegantDoc.com"

				} catch (e) {
					console.log(e);
					this.loading = false
				}
			},
			changeCanvasResolution() {
				this.isCanvasSmall = !this.isCanvasSmall

				this.checkCanvasResolution()
			},
			checkCanvasResolution() {
				const page = document.querySelector('.page')
				const canvas = document.querySelector('.pdf-viewer')

				if (this.isCanvasSmall) {
					page.style.display = 'grid'
					page.style.placeContent = 'center'
					page.style.margin = '0 auto'
					page.style.width = 'auto'
					canvas.querySelector('canvas').style.maxHeight = '80vh'
					canvas.querySelector('canvas').style.height = '1500px'
					page.style.height = '1000px'
					page.style.maxHeight = '70vh'
					page.style.maxWidth = '80%'
				} else {
					page.style.display = ''
					page.style.placeContent = ''
					page.style.height = '100%'
					page.style.maxHeight = '100%'
					page.style.maxWidth = '100%'
					page.style.width = '100%'
					canvas.querySelector('canvas').style.maxHeight = '100%'
				}
			},
			async render_doc() {
				try {
					let response = await api.post("/doc/render", {id: this.doc.id, format: 'pdf'});

					if (response.data.msg == 'ok') {
						this.doc_path = response.data.path
					}

				} catch (e) {
					this.$refs.show.showFlashError('Something went wrong.')
				}
			},
			previewTooltip(event) {
				if (event.target === this.$el.querySelector('.annotationLayer')) {
					this.showTooltip = true
					
					this.$nextTick(() => {
						const tooltip = this.$el.querySelector('.preview-tooltip')
						const previewRect = this.$el.querySelector('.annotationLayer').getBoundingClientRect()
						
						const tooltipWidth = tooltip.offsetWidth
						const tooltipHeight = tooltip.offsetHeight

						const mouseX = event.clientX - previewRect.left;
						const mouseY = event.clientY - previewRect.top;
						const maxLeft = previewRect.width - tooltipWidth;
						const maxTop = previewRect.height - tooltipHeight;

						let left = Math.max(0, Math.min(maxLeft, mouseX - tooltipWidth / 2));
						let top = Math.max(0, Math.min(maxTop, mouseY - tooltipHeight));

						if (left < 0) { left = 0 }
						else if (left > maxLeft) { left = maxLeft }

						if (top < 0) { top = 0 }
						else if (top > maxTop) { top = maxTop }

						tooltip.style.left = `${left}px`;
      					tooltip.style.top = `${top}px`;

						setTimeout(() => this.showTooltip = false, 2500)
					})
				}
			}
		},
		async created() {
			this.userInfo = await this.getUser(localStorage.getItem('EDuserID'))
		},
		async mounted() {
			if (this.$route.params.id) {
				this.id = this.$route.params.id;
				await this.getDocs()
			}
			eventBus.$on('contextUpdated', async () => {
				eventBus.$emit('savingData');
				await this.render_doc()
				this.key ++
				// this.checkCanvasResolution()
			});
			eventBus.$on('savingData', () => {
				this.savingData = true
				document.querySelectorAll('.item-btn').forEach(btn => {
					btn.classList.add('item-btn-disabled')
					btn.setAttribute('disabled', 'true')
				})
			});
			if(this.$router.name == "Home"){
				document.querySelector("#app").style.overflow = "hidden"
			};

			setTimeout(() => {
				if (this.doc.template == 'cert') {
					document.querySelector('.pdf-pages-tab').style.display = 'none'
					this.isCanvasSmall = true
					this.checkCanvasResolution()
				}

			}, 500)
		},
		errorCaptured(e) {return false}
	}
</script>

<style scoped lang="scss">
	.coming-soon {
		background: #000;
		color: #fff;
		font-size: 18px;
		padding: 5px 15px;
		width: 100%;
		text-align: center;
	}
	.pdf-pages-tab {
		display: flex;
		align-items: center;
		column-gap: .5rem;

		position: fixed;
		bottom: 1rem;
		left: 75%;
		transform: translateX(-50%);

		background-color: rgba(0, 0, 0, .5);
		padding: .3rem;
		border-radius: .5rem;
	}
	.pdf-pages-tab > span { color: rgb(239, 242, 249); font-weight: 800; }
	.pdf-pages-tab input {
		width: 2rem;
		text-align: center;
		outline: none;
		background-color: rgb(239, 242, 249);
		box-shadow: unset;
		border-radius: 4px;
		padding: 0;
		height: 2rem;
		border-bottom: 1px solid #ccc;
	}
	.pdf-pages-tab input:focus ~ .focus-border {
		width: 100%;
		transition: 0.4s;
	}
	.pdf-pages-tab button {
		background-color: #263a72;
		padding: 0;
		line-height: 1;
		width: 1.9rem;
		height: 2rem;
		border-radius: 4px;
	}

	.zoom-in-out-btn {
		display: grid;
		place-items: center;
	}
	.zoom-in-out-btn * {
		font-size: 1rem;
	}

	.pdf_page_loader {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
	}
	.download-pdf{
		position: fixed;
		top: .4rem;
		right: .4rem;
		z-index: 2;
		background-color: rgb(39, 60, 117, .7);
		color: #fff;
		font-size: 16px !important;
		letter-spacing: 0.02em;
		transition: 200ms ease-in-out background-color;

		&:hover {
			background-color: #090f22;
            color: #fff;
		}
	}

	.loader-animation {
		position: fixed;
		top: .4rem;
		left: calc(50% + .4rem);
		z-index: 2;
		padding: 7px;
		border-radius: 7px !important;
		background-color: rgb(39, 60, 117, .7);
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		&:hover {
			background-color: rgb(39, 60, 117, .7);
		}
		span {
			font-size: 1rem;
		}
	}
	.preview-tooltip {
		position: absolute;
		max-width: 200px;
		// transform: translate(-50%, -105%);
		background-color: rgba(9, 23, 61, 0.8);
		font-size: .9rem;
		color: #fff;
		padding: .5rem;
		border-radius: .3rem;
	}
</style>
