<template>
    <div>
        <b-modal :id="id" 
                class="modal"
                hide-footer
				hide-header
				size="xl"
				body-class="modal-main">
            <a href="#" class="close-btn" @click.prevent="$bvModal.hide(id)"></a>
            <table class="admin-body-table">
                <thead>
                    <tr>
                        <th>Info</th>
                        <th>MSG</th>
                        <th>URL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ event.body ? event.body.info : '' }}</td>
                        <td>{{ event.body ? event.body.msg : '' }}</td>
                        <td>{{ event.body ? event.body.url : '' }}</td>
                    </tr>
                </tbody>
            </table>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            default: 'modal'
        },
        event: { type: Object }
    },
}
</script>

<style>
    table.admin-body-table { width: 100%; }
    table.admin-body-table thead tr th,
    table.admin-body-table tbody tr td {
        padding: 1rem;
        text-align: left;
        border-bottom: 1px solid rgb(216, 216, 216);
    }
</style>