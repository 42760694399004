<template>
    <button class="btn-general">
        <slot>
            {{btn_name}}
        </slot>
        <Flash ref="show" />
    </button>
</template>

<script>
import Flash from '@/components/Atoms/Flash'
export default {
    name: 'Button',
    props: {
        btn_name: { type: String }
    },
    components: { Flash }
}
</script>

<style lang="scss">
    .btn-general {
        font-size: 20px !important;
        padding: 10px;
        border-radius: 10px !important;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: capitalize;
        color: #FFFFFF;
        border-radius: 0;
        background: #046FC8;
        border:0;
        outline: none;
        box-shadow: unset;
        &:hover {
            color: #FFFFFF;
            background: #2192EF;
        }
    }
</style>