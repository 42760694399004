<template>
	<div class="form-group mb-3 color-picker-wrapper">
		<label class="color-name">{{ name }}</label>
		<div @click="show_color_picker = !show_color_picker" class="form-control">
			<div class="gr">
				<svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M2.69667 5.5299L8 0.226562L13.3033 5.5299C14.3522 6.57879 15.0665 7.91517 15.3559 9.37002C15.6453 10.8249 15.4968 12.3329 14.9291 13.7033C14.3614 15.0738 13.4001 16.2451 12.1668 17.0692C10.9334 17.8933 9.48336 18.3332 8 18.3332C6.51665 18.3332 5.0666 17.8933 3.83324 17.0692C2.59987 16.2451 1.63858 15.0738 1.07092 13.7033C0.503256 12.3329 0.354725 10.8249 0.644107 9.37002C0.933489 7.91517 1.64779 6.57879 2.69667 5.5299V5.5299Z" :fill="data_mutation.hex || '#21405b'"/>
				</svg>
				<span style="margin-left: .5rem; font-size: 14px; font-weight: 500;">{{ data_mutation.hex }}</span>
			</div>
			<img src="@/assets/images/remix-icons-fill-design.svg" alt="">
		</div>
		<div v-if="show_color_picker" class="color-picker">
			<sketch-picker @input="updateDesign({[obj_key]: data_mutation.hex})" v-model="data_mutation"/>
		</div>
		<Flash ref="show" />
	</div>
</template>

<script>
    import {Sketch} from 'vue-color';
	import apiCalls from '@/mixins/apiCalls'
	import Flash from '@/components/Atoms/Flash'

    export default {
        components: {
            'sketch-picker': Sketch,
			Flash
        },
		mixins: [apiCalls],
        props:{
            name: { type: String },
			id:{ type: String },
            data: { type: Object },
			obj_key: { type: String }
		},
        data () {
            return {
				show_color_picker: false,
				// This data_mutation is created to store "this.data" in there and we use "this.data_mutation" in this component. Reason: We're not allowed to mutate a prop directly.
				data_mutation: this.data,
				timeout: null
            }
        },
        methods: {
            updateDesign(data) {
				if (!localStorage.accessToken) return
				
				clearTimeout(this.timeout);
				this.timeout = setTimeout(async () => {
					this.docUpdate(data, this.id)
				}, 350);
			}
        },
        mounted() {
			document.querySelector('body').addEventListener('click', (e) => {
				if (!e.target.closest('.color-picker-wrapper')) {
					this.show_color_picker = false
				}
			});
		}
    }
</script>

<style scoped lang="scss">
	@import "@/assets/scss/modals/modal-design.scss";

	.color-name {
		color: rgb(130, 139, 162);
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		margin-bottom: 6px;
	}
	.color-preview {
		margin-top: 6px;
		height: 20px;
		width: 50px;
		color: #fff;
		box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25);
		cursor: pointer;
	}

	.color-picker {
		position: absolute;
		top: 83px;
		right: 0;
		z-index: 2;
	}
	.form-control {
		display: flex;
		align-items: center;
		justify-content: space-between;
		outline: none;
		box-shadow: unset;
		padding: 12px 16px;
		height: 54px;
		border-bottom: 1px solid #ccc;
		border-radius: 4px;
		background-color: #EFF2F9;
		border: none;
		cursor: pointer;
	}
</style>
