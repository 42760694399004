<template>
	<div class="">
		<b-modal :id="id"
				 class="modal"
				 hide-footer
				 hide-header
				 size="md"
		>
			<a href="#" class="close-btn" @click.prevent="$bvModal.hide(id)"></a>
			<h1 class="title text-center">Confirm action</h1>
			<h5 class="text-center mb-3">
				{{confirm_message}}
				<span class="doc-title">{{docTitle}}</span> ?
			</h5>
			<div class="btns">
				<button @click.prevent="$bvModal.hide(id), removeDoc()" class="btn btn-yes">Yes</button>
				<button class="btn btn-no" @click.prevent="$bvModal.hide(id)">No</button>
			</div>
		</b-modal>
		<Flash ref="show"/>
	</div>

</template>

<script>
	import {eventBus} from '@/main'
	import Flash from "@/components/Atoms/Flash";
	import {api} from "@/mixins/axios";
	export default {
		name: 'ModalConfirm',
		components: {
			Flash
		},
		props:{
			confirm_message: {
				type: String,
				default: 'Are you sure?'
			},
			id: {
				type: String,
				default: 'modal'
			},
			docId:{
				type:String
			},
			docTitle:{
				type:String
			}
		},
		methods:{
			async removeDoc(){
				if (!localStorage.accessToken) return
				try {
					let response = await api.post(`/doc/delete`, {id: this.docId});

					if (response.data.msg == 'ok') {
						eventBus.$emit('docDeleted');
						this.$refs.show.showFlashSuccess('Doc deleted');
					}

				} catch (e) {
					console.log(e);
				}
			},

		}
	}
</script>

<style lang="scss">
	@import "@/assets/scss/modals/modal-confirm.scss";
</style>
