<template>
    <div>
        <b-modal :id="id" 
                class="modal"
                hide-footer
				hide-header
				size="xl"
				body-class="modal-main">
            <a href="#" class="close-btn" @click.prevent="$bvModal.hide(id)"></a>
            <div>
                <h2>Paste an Image URL</h2>
				<form @submit.prevent="image_render">
					<div class="form-group mb-3 image-modal-form-control">
						<input v-model="url" class="company-name form-control" type="text" placeholder="Type an Image URL" required>
						<span class="focus-border"></span>
					</div>
					<Button class="insert-btn">Insert</Button>
				</form>
                <h1 class="or">
                    <div></div>
                    <span>or</span>
                    <div></div>
                </h1>
                <h2>Upload from you computer</h2>
                <div class="mb-3 image-modal-form-control">
                    <label for="file-input-markdown" class="form-label">
                        <div class="form-file">

                            <input @change="upload_file_to_markdown($event)" type="file" name="file_markdown" id="file-input-markdown" style="display: none">
                            
                            <font-awesome-icon icon="fa-solid fa-image"/>
                            <span>Image Upload</span>
                        </div>
                    </label>
                </div>
            </div>
        </b-modal>
		<Flash ref="show" />
    </div>
</template>

<script>
import editor from "@/mixins/editor";
import { eventBus } from "@/main";
import {api} from "@/mixins/axios";
import Button from '@/components/Atoms/Button'
import Flash from '@/components/Atoms/Flash'

export default {
    props: {
        id: {
            type: String,
            default: 'modal'
        },
    },
	components: {
		Button,
		Flash
	},
	mixins: [editor],
	data() {
		return {
			url: ''
		}
	},
	methods: {
		async upload_file_to_markdown(e) {
			let formData = new FormData()
			const fileSizeInBytes = e.target?.files[0].size
            const fileSizeInMegaBytes = (fileSizeInBytes / (1024 * 1024)).toFixed(2)
			if (fileSizeInMegaBytes < 10) {
				formData.append('file', e.target?.files[0])
				try {
					let response = await api.post("/files/upload", formData);
					
					if (response.data.msg == 'ok') {
						eventBus.$emit("image_upload", response.data.url);
						this.$bvModal.hide(this.id)
					}
				} catch (e) {
					this.$refs.show.showFlashError('Something went wrong')
				}
			} else {
				this.$refs.show.showFlashError('File needs to be less than 10MB')
			}
		},
		image_render() {
			eventBus.$emit("image_upload", this.url);
			this.$bvModal.hide(this.id)
			this.url = ''
		}
	},
	mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.url = ''
    })
  }
}
</script>

<style lang="scss">
	@import "@/assets/scss/modals/modal-upgrade.scss";

    .modal {
        text-align: center;
    }
    .or {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center; 
        margin: 2rem 0;
    }
    .or div {
        width: 100%;
        height: 2px;
        background-color: #898989;
    }
    .or span {
        display: inline-block;
        padding: 0 1rem;
        transform: translateY(-5px);
        color: #898989;
    }
    .image-modal-form-control { margin-top: 2rem; }


    .form-group {
		position: relative;
	}

	.focus-border {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0;
		height: 2px;
		background-color: #2192EF;
		transition: 0.4s;
	}

	.form-control {
		outline: none;
		box-shadow: none;
		border-radius: 3px;
		padding: 12px 16px;
		border-bottom: 1px solid #ccc;
	}

	.form-control:focus ~ .focus-border {
		width: 100%;
		transition: 0.4s;
	}

	.form-label {
		color: rgb(130, 139, 162);
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 6px;
		width: 100%;
	}

	.company-name {
		background-color: rgb(239, 242, 249);
		border: 0;
		border-radius: unset;
	}

	.form-file {
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 12px;
		margin-top: 6px;
		height: 54px;
		border-radius: 4px;
		background-color: #eff2f9;
		color: rgb(130, 139, 162);
		&:hover {
			span {
				color: #5b3eb7;
				&.error{
					color: #e74c3c;
				}
			}
			svg {
				color: #5b3eb7;
			}
		}

		span {
			&.error{
				color: #e74c3c;
			}
		}
		svg {
			font-size: 38px;
			margin-right: 15px;
		}
	}

	.insert-btn {
		background-color: #263b73;
		transition: 200ms ease-in-out background-color;

		&:hover {
			background-color: #090f22;
		}
	}
</style>